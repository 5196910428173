/* @jsxImportSource react */

import type { FC, ReactNode } from 'react';

export type SlotsContainerProps = {
  children?: ReactNode;
};

export const SlotsContainer: FC<SlotsContainerProps> = ({ children }) => (
  <div data-testid="header-slots" className="mr-1 flex gap-1.5 lg:gap-3 xl:mr-0">
    {children}
  </div>
);
