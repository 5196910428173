'use client';

import type { ReactNode, FC } from 'react';
import { useMemo, createContext, useEffect, startTransition, useState } from 'react';
import { setCookie } from 'nookies';
import useSWRMutation from 'swr/mutation';
import type { ApiKeyManager } from '@empiriecom/mybuy-session/ApiKey';

import { useCookieConsent } from '../../hooks/useCookieConsent/useCookieConsent';
import { LoginStateProvider } from '../LoginStateProvider/LoginStateProvider';
import { useCookies } from '../CookieProvider/CookieProvider';
import { getEcState } from '../../utils/ecState/ecState';

export const kkBRECookieName = 'kkBRE';

export interface SessionContextProps {
  jwtToken?: string;
}

export const SessionContext = createContext<SessionContextProps>({
  jwtToken: '',
});

const COOKIE_OPTIONS = {
  path: '/',
  maxAge: 86400, // 90 days in seconds
  secure: false, // true would be nice but this is what intershop defines,
  sameSite: 'lax' as 'lax',
  httpOnly: false,
};

const REVALIDATE_TIME = 60 * 30; // 30 minutes

const getExpireTimeStamp = (expire: number) => (expire - REVALIDATE_TIME) * 1000;

const isExpired = (expire: number) => getExpireTimeStamp(expire) < Date.now();

const getTimeUntilExpired = (expire: number) => getExpireTimeStamp(expire) - Date.now();

type TokenContents =
  | {
      ts: string | undefined;
      type: number;
      role: number;
      ct?: number;
      fi: string;
      cc?: number;
      exp: number;
    }
  | undefined;

const getLoginStatus = async (apiKeyManager: ApiKeyManager) => {
  let apiKey = await apiKeyManager.getApiKey();
  let contents = apiKeyManager.getTokenContents(apiKey) as TokenContents;
  const expire = contents?.exp;

  if (expire && isExpired(expire)) {
    await apiKeyManager.invalidateSessionKey();
    apiKey = await apiKeyManager.getApiKey();
    contents = apiKeyManager.getTokenContents(apiKey) as TokenContents;
  }

  return {
    apiKey,
    contents,
  };
};

export const SessionProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const { getCookies } = useCookies();
  const initialState = getEcState(getCookies());
  const [apiKeyM, setApiKeyM] = useState<ApiKeyManager | null>(null);

  const isCookieAllowed = useCookieConsent('C0004');

  const { data, trigger } = useSWRMutation('session', async () => {
    const { apiKeyManager } = await import('@empiriecom/mybuy-session');
    setApiKeyM(apiKeyManager);
    return getLoginStatus(apiKeyManager);
  });

  useEffect(() => {
    startTransition(() => {
      trigger();
    });
  }, [trigger]);

  useEffect(() => {
    if (apiKeyM) {
      apiKeyM.addSessionChangedListener(trigger);
    }
  }, [apiKeyM, trigger]);

  const { apiKey, contents } = { ...data };

  useEffect(() => {
    if (contents) {
      setTimeout(trigger, getTimeUntilExpired(contents.exp));

      if (contents.cc && isCookieAllowed) {
        setCookie(null, kkBRECookieName, contents.cc.toString(), COOKIE_OPTIONS);
      }
    }
  }, [contents, trigger, isCookieAllowed]);

  const value = useMemo(
    () => ({
      jwtToken: apiKey,
    }),
    [apiKey],
  );

  return (
    <SessionContext.Provider value={value}>
      <LoginStateProvider
        loggedIn={initialState.loggedIn || !!(contents && contents.type && contents.type !== 0)}
        softLoggedIn={Boolean(contents && contents.type === 1)}
      >
        {children}
      </LoginStateProvider>
    </SessionContext.Provider>
  );
};
