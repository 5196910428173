import { defineMessages, useIntl } from 'react-intl';
import { Typography } from '@packages/shared/src/components/Typography/Typography';
import { Box } from '@packages/shared/src/components/Box/Box';
import { LoginButton } from '@packages/shared/src/components/Button/LoginButton';
import { useDeviceType } from '@packages/shared/src/hooks/useDeviceType/useDeviceType';
import { useI18n } from '@packages/shared/src/hooks/useI18n/useI18n';
import { useConfig } from '@packages/shared/src/hooks/useConfig/useConfig';
import { useLoginState } from '@packages/shared/src/hooks/useLoginState/useLoginState';
import { getLocalizedPath } from '@packages/shared/src/utils/getLocalizedPath/getLocalizedPath';
import { Wishlist32 } from '@packages/themes/icons';
import { useAuthQuery } from '@packages/shared/src/hooks/useAuthQuery/useAuthQuery';
import { useEffect } from 'react';
import { WishlistSkusDocument } from '@packages/gql/generated/shopping/WishlistSkusDocument';
import { Slot } from './Slot';
import { LoginDialogComposed } from '../../../LoginDialog/LoginDialogComposed/LoginDialogComposed';

const messages = defineMessages({
  title: {
    id: 'slot.wishlist.label',
    defaultMessage: 'Merkzettel',
  },
  overlayHeadline: {
    id: 'slot.wishlist.overlay.headline',
    defaultMessage: 'Du vermisst Artikel, die du dir gemerkt hast?',
  },
  overlayContent: {
    id: 'slot.wishlist.overlay.content',
    defaultMessage: 'Dann melde dich bitte an, um deine gespeicherten Artikel zu sehen.',
  },
});

const DesktopPopoverContent = ({ onLogin }: { onLogin: () => void }) => {
  const { formatMessage } = useIntl();
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', p: 2, pt: 4 }}>
      <Typography variant="h5" sx={{ mb: 2 }}>
        {formatMessage(messages.overlayHeadline)}
      </Typography>
      <Typography variant="body2" sx={{ mb: 2 }}>
        {formatMessage(messages.overlayContent)}
      </Typography>
      <Box sx={{ alignSelf: 'flex-end' }}>
        <LoginButton fullWidth onClick={onLogin} />
      </Box>
    </Box>
  );
};

/** Wishlist slot with count badge and login popover. On mobile devices the slot is a link and has no popover */
export const WishlistSlot = () => {
  const { formatMessage } = useIntl();
  const { language } = useI18n();
  const { isMobile } = useDeviceType();
  const { locale } = useI18n();
  const [{ data }, executeQuery] = useAuthQuery({
    query: WishlistSkusDocument,
    variables: { locale },
    requestPolicy: 'cache-and-network',
  });
  const { loggedIn } = useLoginState();
  const { lifeSupportForBasicallyDeadTenant, order } = useConfig();

  useEffect(() => {
    // this is a workaround to update the wishlist count badge when the wishlist is updated outside the next.js app
    // the event could be dispatched from the legacy fragments order or wishlist
    const eventListener: EventListener = () => {
      executeQuery({ requestPolicy: 'network-only' });
    };

    if (window) {
      window.addEventListener('wishlistUpdated', eventListener);
    }

    return () => {
      if (window) {
        window.removeEventListener('wishlistUpdated', eventListener);
      }
    };
  }, [executeQuery]);

  const href = getLocalizedPath(order.paths.wishlist, locale);

  return (
    <LoginDialogComposed>
      {(openLoginDialog) => (
        <Slot
          language={language}
          useRouterLink={false}
          icon={<Wishlist32 />}
          badgeCount={data?.wishlist.count}
          title={formatMessage(messages.title)}
          href={href}
        >
          {!isMobile &&
            !loggedIn &&
            data?.wishlist.count === 0 &&
            !lifeSupportForBasicallyDeadTenant && (
              <DesktopPopoverContent onLogin={openLoginDialog} />
            )}
        </Slot>
      )}
    </LoginDialogComposed>
  );
};
