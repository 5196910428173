import { type FC } from 'react';
import type { ButtonProps } from '@mui/material';
import { Button, Tooltip } from '@mui/material';
import { useTracking } from '@packages/tracking/src/hooks/useTracking/useTracking';
import { type GlycerinButtonClickPayload } from '@packages/tracking/src/types/payloads';
import { type GTMEventGlycerinButtonClick } from '@packages/tracking/src/types/events';
import { useIsBot } from '@packages/tracking/src/hooks/useIsBot/useIsBot';
import { getCanonicalUrl } from '../../utils/getCanonicalUrl/getCanonicalUrl';
import { CircularProgress } from '../CircularProgress';
import { Box } from '../Box';

export type ColorButtonProps = {
  /** color name */
  name: string;
  /** color value for css background color */
  color?: string;
  /** color (pattern) image url */
  image?: string;
  /** should the image be fitted into the button */
  fitBackgroundImage?: boolean;
  /** if color is active */
  active?: boolean;
  /** small color button */
  minimal?: boolean;
  /** rounded color button */
  rounded?: boolean;
  isSelected?: boolean;
  clickTrackingProps?: GlycerinButtonClickPayload;
} & Omit<ButtonProps, 'color' | 'children'>;

/**
 * ColorButton component based on https://mui.com/material-ui/react-button/
 * */
export const ColorButton: FC<ColorButtonProps> = ({
  name,
  color,
  image,
  fitBackgroundImage,
  active = false,
  minimal = true,
  rounded = true,
  isSelected = false,
  disabled,
  sx,
  onClick,
  ...rest
}) => {
  const isLoading = isSelected && !active;
  const dispatchGTMEvent = useTracking();
  const { clickTrackingProps, href } = rest;
  const { category, target, detail, label, placement, variantIds, custom } =
    clickTrackingProps ?? {};

  let linkTarget = href;
  const isBot = useIsBot();
  if (isBot) {
    linkTarget = getCanonicalUrl(href ?? '');
  }

  const button = (
    <Button
      variant="text"
      className={`${rounded ? 'rounded' : ''} ${active ? 'active' : ''}`}
      style={{
        background: `${color || 'transparent'} url(${image}) center repeat`,
        ...(fitBackgroundImage && { backgroundSize: 'cover' }),
      }}
      sx={{
        visibility: isLoading ? 'hidden' : 'visible',
        display: 'block',
        width: minimal ? 30 : 36,
        height: minimal ? 30 : 36,
        minWidth: 30,
        minHeight: 'auto',
        borderRadius: 0,
        overflow: 'hidden',
        border: 2,
        borderColor: 'grey.main',
        '&:hover': {
          borderColor: 'grey.main',
        },
        '&.active': {
          outline: '2px solid black',
        },
        '&.rounded': {
          borderRadius: '50%',
        },
        ...sx,
      }}
      onClick={(e) => {
        if (!active && onClick) {
          onClick(e);
        }
        dispatchGTMEvent<GTMEventGlycerinButtonClick>({
          event: 'ButtonClick',
          ButtonClickData: {
            category: category ?? 'color-button-click',
            target: target ?? href,
            detail,
            label,
            placement,
            variantIds,
            custom,
          },
        });
      }}
      disabled={disabled}
      href={linkTarget}
      {...rest}
    />
  );

  return (
    <>
      {isLoading && (
        <Box sx={{ position: 'relative', mx: -0.75 }}>
          <CircularProgress
            sx={{ position: 'absolute', transform: 'translate(-50%, -50%)', ml: 0.75 }}
          />
        </Box>
      )}

      <Tooltip title={name}>{disabled === true ? <span>{button}</span> : button}</Tooltip>
    </>
  );
};
