'use client';

import { type PropsWithChildren } from 'react';
import { type VariantProps } from 'class-variance-authority';
import { cn } from '../../utils/cn/cn';
import { Button } from '../Button/Button';
import { notificationVariants } from './notificationVariants';

export type NotificationProps = PropsWithChildren &
  VariantProps<typeof notificationVariants> & { className?: string; onClose?: () => void };

export const Notification = ({
  children,
  severity = 'info',
  variant = 'default',
  onClose,
  className,
}: NotificationProps) => (
  <div
    role={severity === 'error' || severity === 'warning' ? 'alert' : 'status'}
    className={cn(notificationVariants({ variant, severity }), className)}
  >
    <span />
    <div className="flex-grow">{children}</div>
    {onClose && (
      <Button onClick={onClose} size="base" className="bg-color-transparent h-4 w-4 border-0 p-0">
        <span className="icon-emp-cancel h-4 w-4" />
      </Button>
    )}
  </div>
);
