import { Box } from '@packages/shared/src/components/Box/Box';
import { ConditionalWrapper } from '@packages/shared/src/components/ConditionalWrapper/ConditionalWrapper';
import { Tooltip } from '@packages/shared/src/components/Tooltip/Tooltip';
import type { LinkBoxType } from './types';
import { registerComponents } from '../ComponentRecognizer/componentRegistry';
import { ComponentTypes } from '../../../interfaces';
import { ImageTeaser } from '../ImageTeaser/ImageTeaser';
import { LinkBoxItemContent } from './LinkBox.item.content';
import { useGetTooltipContent } from '../../hooks/useGetTooltipContent';
import { LinkBoxCaption } from './LinkBox.caption';
import { useAssignLinkTargetBasedOnBotStatus } from '../../hooks/useAssignLinkTargetBasedOnBotStatus';
import { buildLinkWithArguments } from '../../utils/buildLinkWithArguments';

/**
 * The LinkBox component displays a teaser with maintainable texts and different layout variants
 */
export const LinkBox = ({ data }: { data: LinkBoxType }) => {
  const {
    // layout of the box
    // Options:
    // 	• Bottom (Standard, Text is below the image)
    // 	• Top (Text is above the image)
    // 	• Left (Text is to the left of the image)
    // 	• Right (Text is to the right of the image)
    behavior_paragraph_alignment: layoutAlignment = 'bottom',
    // Options:
    // 	• standard (without background color)
    // 	• boxed (with bg color)
    behavior_paragraph_variant: layoutVariant = 'standard',
    // Custom Styles
    behavior_paragraph_margin: layoutMargin = '0',
    behavior_paragraph_borderRadius: borderRadius,
    // Cta Type
    behavior_paragraph_cta_type: ctaType,
    // Options: true, false | Note: Lazy loading should actually always be activated by default
    behavior_paragraph_lazy_load: isLazyLoading = true,
    // Background color for the boxed variant
    behavior_paragraph_background_color: backgroundColor,

    // Tooltip
    behavior_paragraph_tooltip_activation: tooltipActivation,
    behavior_paragraph_tooltip_positioning: tooltipPositioning,

    // promotion item
    promotion_item: tooltipDataPromotion,
    content_snippet_bundled: tooltipDataContentSnippet,

    // link
    field_link: link,
    behavior_paragraph_link_target: linkTarget,
    field_link_anchor: linkAnchor,
    field_link_highlight: linkHighlight,
    field_link_searchfilter: linkSearchFilter,
    field_tracking: linkTracking,
    field_bonus_number: bonusNumber,

    // custom identifier e.G. dynamic yield
    behavior_paragraph_custom_id: customId,
  } = data;

  const tooltipContent = useGetTooltipContent(tooltipDataPromotion, tooltipDataContentSnippet);

  const tmpLink: string = link
    ? buildLinkWithArguments(link, {
        field_link_anchor: linkAnchor,
        field_link_highlight: linkHighlight,
        field_link_searchfilter: linkSearchFilter,
        field_tracking: linkTracking,
        field_bonus_number: bonusNumber,
      })
    : '';

  const assignLinkTarget = useAssignLinkTargetBasedOnBotStatus();
  const finalLink = assignLinkTarget(tmpLink);

  const isLandscape: boolean = layoutAlignment === 'left' || layoutAlignment === 'right';

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: isLandscape ? 'row' : 'column',
        alignItems: 'flex-start',
        height: '100%',
        ...(layoutVariant === 'boxed' && {
          backgroundColor: backgroundColor || 'info.light',
        }),
        ...(layoutVariant === 'standard' && {
          backgroundColor: backgroundColor || 'transparent',
        }),
        margin: layoutMargin,
        borderRadius,
        overflow: 'hidden',
      }}
      data-dy={customId}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          ...((layoutAlignment === 'top' || layoutAlignment === 'left') && { order: 1 }),
          width: isLandscape ? '50%' : '100%',
          height: isLandscape ? '100%' : 'auto',
          '& img, & a': {
            display: 'block',
            width: '100%',
          },
          '& img': {
            objectFit: 'cover',
            height: isLandscape ? '100%' : 'auto !important',
          },
        }}
      >
        <ConditionalWrapper
          condition={!!(tooltipContent && !!tooltipActivation)}
          wrapper={(ch) => (
            <Tooltip variant="default" content={tooltipContent} positioning={tooltipPositioning}>
              {ch}
            </Tooltip>
          )}
        >
          <ImageTeaser
            data={data}
            link={finalLink}
            linkTarget={linkTarget}
            isLazyLoading={isLazyLoading}
          />
        </ConditionalWrapper>
        {ctaType === 'caption' && <LinkBoxCaption data={data} link={finalLink} />}
      </Box>
      <LinkBoxItemContent
        data={{
          ...data,
          behavior_paragraph_variant: layoutVariant,
        }}
        link={finalLink}
        linkTarget={linkTarget}
        isLandscape={isLandscape}
      />
    </Box>
  );
};

// Needed to run this component in Storybook and Jest tests. Register the component in a global componentRegistry object
registerComponents({
  [ComponentTypes.LinkBox]: LinkBox,
});
