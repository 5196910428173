import { CmsComponents } from '../ComponentRecognizer/ComponentRecognizer';
import type { CmsData, MenuType } from '../../../interfaces';
import { registerFlexibleComponents } from '../ComponentRecognizer/registerFlexibleComponents';
import { ContentWithMenu } from './ContentWithMenu';
import { SchemaOrg } from '../SchemaOrg/SchemaOrg';

// register the dynamic components
registerFlexibleComponents();

export const Content = ({
  data,
  menuData,
  token,
}: {
  data: CmsData;
  menuData?: MenuType;
  token?: string;
}) => {
  const pageTemplate = data.type === 'storefront_content' ? 'homepage' : 'ServiceUndHilfe';
  if (menuData) {
    return (
      <>
        <SchemaOrg data={data} />
        <ContentWithMenu menuData={menuData} currentPath={data.path?.[0].alias}>
          <CmsComponents paragraphs={data.paragraph} pageTemplate={pageTemplate} token={token} />
        </ContentWithMenu>
      </>
    );
  }

  return (
    <>
      <SchemaOrg data={data} />
      <CmsComponents paragraphs={data.paragraph} pageTemplate={pageTemplate} token={token} />
    </>
  );
};
