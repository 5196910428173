import DOMPurify from '@packages/config/utils/dompurify';
import { useIsBot } from '@packages/tracking/src/hooks/useIsBot/useIsBot';
import { Table } from '@packages/shared/src/components/Table/Table';
import { TableHead } from '@packages/shared/src/components/TableHead/TableHead';
import { TableRow } from '@packages/shared/src/components/TableRow/TableRow';
import { TableCell } from '@packages/shared/src/components/TableCell/TableCell';
import { TableBody } from '@packages/shared/src/components/TableBody/TableBody';
import type { HtmlTableEditorType } from './types';

export enum CmsTableTestIds {
  main = 'cmsTable',
}

const stripSearchParamsFromAnchors = (html: string) =>
  html.replace(/<a\s+[^>]*href="([^"]+)"[^>]*>/gi, (match, href) => {
    try {
      const url = new URL(href);
      url.search = '';
      return match.replace(href, url.toString());
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
    } catch (e) {
      return match;
    }
  });

export const CmsTable = ({ data }: { data: HtmlTableEditorType }) => {
  const { tableHead, tableBody, caption } = data.table_json;
  const isBot = useIsBot();

  return (
    <Table
      data-testid={CmsTableTestIds.main}
      sx={{ width: 'max-content', margin: data.field_margin, padding: data.field_padding }}
    >
      {caption && <caption>{caption}</caption>}
      {tableHead && (
        <TableHead>
          <TableRow>
            {tableHead.map((headCell) => (
              <TableCell
                colSpan={headCell.colspan ? parseInt(headCell.colspan, 10) : undefined}
                variant="head"
                dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(headCell.th) }}
                // INSPIRE-3502 override theme style to not make first column fixed bold
                sx={{ ':first-of-type': { fontWeight: 'inherit', verticalAlign: 'initial' } }}
              />
            ))}
          </TableRow>
        </TableHead>
      )}
      <TableBody>
        {tableBody.map((bodyRow, outerIndex) => (
          <TableRow>
            {bodyRow.map((bodyCell, innerIndex) => {
              // this id is needed to apply a corresponding style
              const id = `Table${data.id}${outerIndex}${innerIndex}`;
              const { th = '', td = '', colspan, rowspan, style } = bodyCell;
              const sanitizedCell = DOMPurify.sanitize(
                isBot ? stripSearchParamsFromAnchors(td || th) : td || th,
              );
              return (
                <>
                  <TableCell
                    variant={th ? 'head' : 'body'}
                    colSpan={colspan ? parseInt(colspan, 10) : undefined}
                    rowSpan={rowspan ? parseInt(rowspan, 10) : undefined}
                    dangerouslySetInnerHTML={{
                      __html: sanitizedCell,
                    }}
                    id={id}
                    // INSPIRE-3502 override theme style to not make first column fixed bold
                    sx={{ ':first-of-type': { fontWeight: 'inherit', verticalAlign: 'initial' } }}
                  />
                  {/* use this style syntax to apply drupals legacy css style string (e.g. 'height:200px;width:300px;') on this cell */}
                  {style && (
                    <style>{`
                      #${id} {
                        ${style}
                      }
                    `}</style>
                  )}
                </>
              );
            })}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};
