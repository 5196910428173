import { startTransition, useState, type ReactNode } from 'react';

import { ErrorBoundary } from '@packages/shared';

import { usePathname } from 'next/navigation';
import { cn } from '@packages/shared/src/utils/cn/cn';
import { ResponsiveHeaderErrorFallback } from './ResponsiveHeaderErrorFallback';

export type SearchFieldSlotProps = {
  isMobileSearchAlwaysVisible: boolean;
};

export type HeaderIconsSlotProps = {
  toggleMobileSearchField: () => void;
  isMobileSearchAlwaysVisible: boolean;
  isMobileSearchVisible: boolean;
};

export type ResponsiveHeaderLayoutProps = {
  top?: ReactNode;
  logo: ReactNode;
  icons: (props: HeaderIconsSlotProps) => ReactNode;
  searchField: (props: SearchFieldSlotProps) => ReactNode;
  /** Navigation is wrapped in a `position:relative` Box, to allow full-bleed break-out of the header */
  navigation: ReactNode;
  bottom?: ReactNode;
};

export const ResponsiveHeaderLayout = ({
  top,
  logo,
  searchField,
  icons,
  navigation,
  bottom,
}: ResponsiveHeaderLayoutProps) => {
  const [isMobileSearchVisible, setIsMobileSearchVisible] = useState(false);
  const pathname = usePathname();

  const isMobileSearchAlwaysVisible = pathname?.startsWith('/s/') ?? false; // NOTE `?` and `?? false` is a workaround for pages/app router parallel operation, where usePathname can theoretically return null
  const toggleMobileSearchField = () =>
    startTransition(() => setIsMobileSearchVisible((prev) => !prev)); // useCallback?

  // TODO later on remove some wrapping Stacks/Boxes and just inline the slot contents with a given gridArea (like the navigation), but for backwards compatibility we wrap it for now
  // TODO later on remove top/bottom slots when AppBanner and Usp are split from the Header
  return (
    <header
      className={cn(
        `grid [grid-template:'top_top_top_top'_auto_'button_logo_space_icons'_auto_'nav_nav_nav_nav'_1fr_'bottom_bottom_bottom_bottom'_auto_/_auto_auto_1fr_auto]`,
        'mx-auto w-full max-w-screen-xl',
        'items-center max-lg:shadow-sm lg:shadow-none lg:[--background-bleed:-50vw]',
      )}
      // Some backgrounds for the navigation extend outside of the default container width of the page}
      // The best way would be to accurately calculate the bleed, like calc(-0.5 * (var(--page-width-without-scrollbar) - var(--theme-width)))
      // Because scrollbar width is hard to determine at the moment using CSS only, we instead use large bleed and just clip it on the body (using overflow-x: hidden)
    >
      {/* NOTE just used as a workaround for the AppBanner currently being part of the header */}
      {top && (
        <div className="[grid-area:top] md:hidden">
          <ErrorBoundary fallback={<ResponsiveHeaderErrorFallback />}>{top}</ErrorBoundary>
        </div>
      )}
      <div className="mx-1 [grid-area:logo] xl:mx-0">
        <ErrorBoundary fallback={<ResponsiveHeaderErrorFallback />}>{logo}</ErrorBoundary>
      </div>
      {/* NOTE this is a workaround for the way the search slot currently works, as it brings its own popup (with its own searchbox included)
      later on, this should probably be refactored so that the header is fully in control of the searchbox, including the desired backdrop (that is currently part of the Slot's popup) */}
      <div
        className={cn(
          isMobileSearchAlwaysVisible ? 'block' : 'hidden',
          'relative [grid-area:nav] lg:block lg:[grid-area:space]',
          // relative positioning is needed for the overlay effect when expanding the search field on non-SERP pages
        )}
      >
        <div
          className={cn(
            isMobileSearchVisible || isMobileSearchAlwaysVisible
              ? 'max-lg:flex max-lg:flex-col'
              : 'max-lg:hidden',
            isMobileSearchAlwaysVisible ? 'max-lg:static' : 'max-lg:absolute',
            'max-lg:z-tooltip bg-color-grey-light lg:mx-6.5',
          )}
        >
          <ErrorBoundary fallback={<ResponsiveHeaderErrorFallback />}>
            {searchField({ isMobileSearchAlwaysVisible })}
          </ErrorBoundary>
        </div>
      </div>
      <div className="flex overflow-hidden [grid-area:icons]">
        <ErrorBoundary fallback={<ResponsiveHeaderErrorFallback />}>
          {icons({ isMobileSearchAlwaysVisible, isMobileSearchVisible, toggleMobileSearchField })}
        </ErrorBoundary>
      </div>
      <div className="relative [grid-area:button] lg:[grid-area:nav]">
        <ErrorBoundary fallback={<ResponsiveHeaderErrorFallback />}>{navigation}</ErrorBoundary>
      </div>
      {/* NOTE just used as a workaround for the USP currently being part of the header */}
      {bottom && (
        <div className="[grid-area:bottom]">
          <ErrorBoundary fallback={<ResponsiveHeaderErrorFallback />}>{bottom}</ErrorBoundary>
        </div>
      )}
    </header>
  );
};
