import type { Language, Locale } from '@packages/config';
import { useCookieConsent } from '@packages/shared/src/hooks/useCookieConsent/useCookieConsent';
import { useI18n } from '@packages/shared/src/hooks/useI18n/useI18n';
import { useTracking } from '@packages/tracking/src/hooks/useTracking/useTracking';
import { useEffect } from 'react';

/**
 * Austria -> "at"
 * Switzerland german -> "ch"
 * Switzerland french -> "ch_fr"
 */
const buildLangCode = (language: Language, locale: Locale) => {
  const countryCode = locale.split('-')[1]?.toLowerCase();

  if (language === 'de') {
    return countryCode;
  }

  return `${countryCode}_${language}`;
};

export const Squarelovin = ({ streamType }: { streamType: 'main' | 'teaser' }) => {
  const isAllowed: boolean = useCookieConsent('C0004');
  const dispatchGTMEvent = useTracking();

  const { language, locale } = useI18n();

  useEffect(() => {
    if (isAllowed) {
      dispatchGTMEvent({
        event: 'renderSquarelovinScript',
        squarelovinStreamType: streamType,
        squarelovinStreamLanguageCode: buildLangCode(language, locale),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatchGTMEvent, isAllowed]);

  return (
    <div key="squarelovin-script">
      <div className="squarelovin-container" />
    </div>
  );
};
