import { defineMessages } from 'react-intl';

export const powerSupplyMessages = defineMessages({
  included: {
    id: 'powerSupply-included',
    defaultMessage: 'Ladenetzteil im Lieferumfang enthalten',
  },
  notIncluded: {
    id: 'powerSupply-notIncluded',
    defaultMessage: 'Ladenetzteil im Lieferumfang nicht enthalten',
  },
});
