import { useDeviceType } from '@packages/shared/src/hooks/useDeviceType/useDeviceType';
import { ConditionalWrapper } from '@packages/shared/src/components/ConditionalWrapper/ConditionalWrapper';
import { Container } from '@packages/shared/src/components/Container/Container';
import { Grid } from '@packages/shared/src/components/Grid/Grid';
import type { Alignment } from '../../../interfaces';
import { ComponentRecognizer } from '../ComponentRecognizer/ComponentRecognizer';
import type { CmsGroupProps } from './types';
import { registerComponents } from '../ComponentRecognizer/componentRegistry';
import { ComponentTypes } from '../../../interfaces';
import { useFilterTimeControl } from '../../hooks/useFilterTimeControl';

export enum CmsGroupTestIds {
  main = 'cms-group',
  item = 'cms-group-item',
  noRenderComponentFound = 'cms-group-no-component-found',
}

const mapVerticalAlignment = (type: Alignment) => {
  if (type === 'top') {
    return 'flex-start';
  }
  if (type === 'bottom') {
    return 'flex-end';
  }
  return type;
};

const textAlign = (horizontalAlignment: Alignment) =>
  horizontalAlignment === 'center' ||
  horizontalAlignment === 'left' ||
  horizontalAlignment === 'right'
    ? {
        textAlign: horizontalAlignment,
      }
    : {};

export const CmsGroup = ({ data, sx }: CmsGroupProps) => {
  const {
    field_cells_per_row: cellsPerRowDesktop = data.paragraph ? data.paragraph.length : 0,
    field_cells_per_row_mobile: cellsPerRowMobile = Math.floor(cellsPerRowDesktop / 2) || 1,
    field_col_gap: colGap = '0',
    field_row_gap: rowGap = '0',
    field_margin: margin = '0',
    field_padding: padding = '0',
    field_background_color: backgroundColor = 'transparent',
    field_alignment: horizontalAlignment = 'center',
    field_vertical_alignment: verticalAlignment = 'flex-start',
    field_custom_id: dynamicYieldId,
    field_distance: distanceType,
    behavior_paragraph_borderRadius: borderRadius,
  } = data;
  const filterTimeControl = useFilterTimeControl();
  const { isMobile } = useDeviceType();
  const cellsPerRow = isMobile ? cellsPerRowMobile : cellsPerRowDesktop;
  const contentElements = data.paragraph?.filter((item) => filterTimeControl(item));

  if (contentElements?.length && contentElements?.length > 0) {
    return (
      <ConditionalWrapper
        condition={distanceType === 'fullwidth' || distanceType === 'variation-1'}
        wrapper={(ch) => (
          <Container
            className="cms-group-outher-wrapper"
            sx={{
              ...(distanceType === 'variation-1' && {
                pl: { xs: 3, lg: 0 },
                pr: { xs: 3, lg: 0 },
              }),
            }}
          >
            {ch}
          </Container>
        )}
      >
        <Grid
          className="cms-group"
          container
          columns={cellsPerRow}
          columnSpacing={0}
          spacing={0}
          {...(horizontalAlignment && { justifyContent: horizontalAlignment })}
          {...(verticalAlignment && { alignItems: mapVerticalAlignment(verticalAlignment) })}
          {...textAlign(horizontalAlignment)}
          sx={{
            borderRadius,
            backgroundColor,
            m: `${margin} !important`,
            p: `${padding} !important`,
            width: 'initial', // MUI sets width to 100%, but that won't work with our margin from CMS
            ...sx,
          }}
          data-testid={CmsGroupTestIds.main}
          data-dy={dynamicYieldId}
        >
          {contentElements.map((item) => (
            <Grid
              key={item.id}
              item
              xs={1}
              md={1}
              sx={{
                /* setting MUI's columnSpacing and rowGap on the container is not sufficient and
                 would slightly break the page layouts.
                 We need our own custom tailored column styles here. */
                ...(cellsPerRow > 1 && {
                  flexBasis: () => {
                    if (colGap !== '0') {
                      return `calc((100% - ${colGap} * (${cellsPerRow} - 1)) / ${cellsPerRow}) !important`;
                    }
                    return `calc(100% / ${cellsPerRow}) !important`;
                  },
                }),
                marginBottom: rowGap,
                marginRight: cellsPerRow > 1 ? colGap : 0,
                ...(cellsPerRow > 1 && {
                  [`&:nth-of-type(${cellsPerRow}n+0)`]: {
                    marginRight: '0',
                  },
                }),
                '& > .cms-group': { height: '100%' }, // vertically stretch sub groups
              }}
              data-testid={CmsGroupTestIds.item}
            >
              {item && <ComponentRecognizer item={item} />}
            </Grid>
          ))}
        </Grid>
      </ConditionalWrapper>
    );
  }

  return <div data-testid={CmsGroupTestIds.noRenderComponentFound} />;
};

// Needed to run this component in Storybook and Jest tests. Register the component in a global componentRegistry object
registerComponents({
  [ComponentTypes.Group]: CmsGroup,
  [ComponentTypes.GroupLocalizable]: CmsGroup,
  [ComponentTypes.GroupApp]: CmsGroup,
});
