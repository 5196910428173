import { useDeviceType } from '@packages/shared/src/hooks/useDeviceType/useDeviceType';
import { logger } from '@packages/shared/src/utils/logger/logger';
import { ArrowMediumDown32 } from '@packages/themes/icons';
import { useIntl } from 'react-intl';
import { useSetAtom } from 'jotai';
import type { Promotion } from '@packages/cms-components/src/modules/PromotionBanner/types';
import { useState } from 'react';
import { bridge } from '@empiriecom/mybuy-session/Bridge';
import { Button } from '@packages/shared/src/components/Button/Button';
import { Card } from '@packages/shared/src/components/Card/Card';
import { Grid } from '@packages/shared/src/components/Grid/Grid';
import { Typography } from '@packages/shared/src/components/Typography/Typography';
import { Accordion } from '@packages/shared/src/components/Accordion/Accordion';
import { AccordionSummary } from '@packages/shared/src/components/Accordion/AccordionSummary';
import { AccordionDetails } from '@packages/shared/src/components/Accordion/AccordionDetails';
import { remotePromotion } from '../../atoms';
import type { VoucherItemType } from './types';
import { messages } from './Voucher.messages';

const fetchAndActivatePromotion = async (
  promotionCode: string,
  setVoucher: (promotionData: Promotion) => void,
) => {
  fetch(`/api/promotions/promotion/${promotionCode}`)
    .then((response) => {
      if (response.ok) {
        return response.json();
      }
      throw new Error(response.statusText);
    })
    .then((promotionData: Promotion) => {
      if (promotionData) {
        setVoucher(promotionData);
        window.scrollTo({ top: 0, behavior: 'smooth' });
      } else {
        throw new Error(`no promotion data for promotionCode ${promotionCode}`);
      }
    })
    .catch((error) => {
      logger.error(error, 'set remote promotion error');
    });
};

const ActivateVoucherButton = ({
  promotionCode,
  fullWidth = false,
}: {
  promotionCode?: string | null;
  fullWidth?: boolean;
}) => {
  const { formatMessage } = useIntl();
  const setVoucher = useSetAtom(remotePromotion);
  const [disabled, setDisabled] = useState(false);
  return (
    <Button
      onClick={async () => {
        if (promotionCode) {
          setDisabled(true);
          // INSPIRE-3533: set bonuscodes also for usage by native apps
          if (typeof bridge?.setBonuscodes === 'function') {
            bridge.setBonuscodes(promotionCode);
          }
          // we could use fetchPromoData() here but this action and fetch is done clientside -
          // we would get a CORS-Error -> so instead we fetch our promotion data by our api
          fetchAndActivatePromotion(promotionCode, setVoucher).then(() => setDisabled(false));
        }
      }}
      fullWidth={fullWidth}
      disabled={disabled}
    >
      {formatMessage(messages.voucherActivate)}
    </Button>
  );
};

export const VoucherItem = ({
  voucherData: {
    voucherValue,
    badgeSubline,
    seoHeadline,
    promotionCode,
    seoDescription,
    detailInfos,
  },
  expired,
}: {
  voucherData: VoucherItemType;
  expired: boolean;
}) => {
  const { formatMessage } = useIntl();
  const { isMobile } = useDeviceType();
  return (
    <Card
      sx={{
        boxShadow: 1,
        width: { md: '47.75rem', xs: '21.625rem' },
        margin: 'auto',
        color: expired ? 'text.darkTransparent' : 'text.primary',
      }}
    >
      <Grid container spacing={2} sx={{ p: 2 }} alignItems="center">
        <Grid
          item
          xs={4}
          lg={2}
          sx={{
            color: expired ? 'text.darkTransparent' : 'primary.main',
            textAlign: 'center',
          }}
        >
          <Typography variant="h2" component="div">
            {voucherValue}
          </Typography>
          <Typography variant="body2" component="div" sx={{ overflowWrap: 'break-word' }}>
            {badgeSubline}
          </Typography>
        </Grid>
        <Grid item xs={8} lg={10}>
          <Typography variant="h4" component="div" mb={2} sx={{ overflowWrap: 'break-word' }}>
            {seoHeadline}
          </Typography>
          {!expired && !isMobile && <ActivateVoucherButton promotionCode={promotionCode} />}
        </Grid>
        {!expired && isMobile && (
          <Grid item xs={12}>
            <ActivateVoucherButton promotionCode={promotionCode} fullWidth />
          </Grid>
        )}
      </Grid>
      <Accordion
        disableGutters
        sx={{
          display: 'flex',
          flexDirection: 'column-reverse',
          color: expired ? 'text.darkTransparent' : 'text.primary',
        }}
      >
        <AccordionSummary
          expandIcon={<ArrowMediumDown32 />}
          sx={[{ '&.Mui-expanded': { borderTop: '1px solid', borderTopColor: 'grey.main' } }]}
        >
          {formatMessage(messages.voucherMoreInformation)}
        </AccordionSummary>
        <AccordionDetails sx={{ padding: 2, typography: 'body2' }}>
          {seoDescription}
          <Grid container sx={{ mt: 2.5 }}>
            {detailInfos?.map(({ key, value }, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <Grid item xs={12} lg={6} key={index}>
                <Typography variant="body2" sx={{ fontWeight: 'bold', mr: 0.5 }} component="span">
                  {key}
                </Typography>
                {value}
              </Grid>
            ))}
          </Grid>
        </AccordionDetails>
      </Accordion>
    </Card>
  );
};
