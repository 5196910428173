'use client';

import type { JSX } from 'react';
import { useSurveyData } from '../../hooks/useSurveyData';
import { SurveyWrapper } from './SurveyWrapper';

/**
 * Displays Surveys on Pages.
 *
 * @returns {JSX.Element|null} Rendered survey or null if no survey are present
 */
export const Survey = (): JSX.Element | null => {
  const data = useSurveyData();

  if (data && data.data) {
    return <SurveyWrapper data={data.data} />;
  }

  return null;
};
