/* istanbul ignore file */
import type {
  AnyVariables,
  DocumentInput,
  OperationContext,
  UseMutationExecute,
  UseMutationResponse,
} from 'urql';
import { useMutation } from 'urql';
import { useMemo } from 'react';
import { useSession } from '../useSession/useSession';

// improve possibility for passing options if needed
export const useAuthMutation = <Data = any, Variables extends AnyVariables = AnyVariables>(
  query: DocumentInput<Data, Variables>,
): UseMutationResponse<Data, Variables> => {
  const { jwtToken } = useSession();
  const [state, executable] = useMutation(query);
  const authExecutable: UseMutationExecute<Data, Variables> = useMemo(
    () => (variables: Variables, context?: Partial<OperationContext>) => {
      const authContext = {
        ...context,
        fetchOptions: {
          headers: { 'x-ec-token': jwtToken || '' }, // TODO what if no token??
        },
      } as unknown as OperationContext;
      if (process.env.STORYBOOK && authContext.fetchOptions) {
        // this applies only for storybook to enable msw mocking
        // see https://github.com/mswjs/msw/issues/1593
        (authContext.fetchOptions as { headers: { accept: string } }).headers.accept = '*/*';
      }
      return executable(variables!, authContext);
    },
    [executable, jwtToken],
  );

  return [state, authExecutable];
};
