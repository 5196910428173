import { type FC } from 'react';
import { defineMessages, useIntl } from 'react-intl';

import { Button } from '@packages/shared/src/components/Button/Button';
import { DialogPreset } from '@packages/shared/src/components/DialogPreset/DialogPreset';
import { Stack } from '@packages/shared/src/components/Stack/Stack';
import { Typography } from '@packages/shared/src/components/Typography/Typography';

import { useAtom } from 'jotai';
import { DynamicSearchField } from '../../Header/Toolbar/SearchField/SearchField.dynamic';
import { isInspiringSearchOnboardingOpenAtom } from '../../Header/Header.atoms';
import { ONBOARDING_QUERY_PARAM } from '../../Header/useInspiringSearchSettings.shared';

const messages = defineMessages({
  modalTitle: {
    id: 'search.inspiringSearch.onboardingModal.modalTitle',
    defaultMessage: 'Inspirierende Suche',
  },
  promptHeading: {
    id: 'search.inspiringSearch.onboardingModal.promptHeading',
    defaultMessage: 'Entdecke die Zukunft des Shoppings',
  },
  prompt: {
    id: 'search.inspiringSearch.onboardingModal.prompt',
    defaultMessage: 'Probiere es aus: Stelle eine Frage oder suche nach einem ganzen Satz.',
  },
  explanationHeading: {
    id: 'search.inspiringSearch.onboardingModal.explanationHeading',
    defaultMessage: 'Wir liefern dir inspirierende Vorschläge passend zu deiner Suchanfrage.',
  },
  explanation: {
    id: 'search.inspiringSearch.onboardingModal.explanation',
    defaultMessage:
      'Du suchst nach einem Geschenk für deine Mama? Dann kannst du bei uns nun nach „Geschenk für Mama“ suchen und erhältst die für dich passenden Vorschläge.',
  },
  closeLink: {
    id: 'search.inspiringSearch.onboardingModal.closeLink',
    defaultMessage: 'Schließen',
  },
});

/**
 * Onboarding modal to get users more familiar with the new AI-enhanced search functionality
 * */
export const InspiringSearchOnboardingModal: FC = () => {
  const { formatMessage } = useIntl();

  const [open, setOpen] = useAtom(isInspiringSearchOnboardingOpenAtom);

  const closeModal = () => setOpen(false);

  return (
    <DialogPreset
      open={open}
      onClose={closeModal}
      title={formatMessage(messages.modalTitle)}
      maxWidth="md"
    >
      <Stack px={1.5} py={3} flex={1} bgcolor="grey.light" minHeight={0} overflow="auto">
        <Stack
          padding={3}
          gap={1.5}
          borderRadius="3px"
          sx={{
            backgroundImage: 'url(/search-assets/inspiring-search-background.svg)',
            backgroundSize: '100% 100%',
          }}
        >
          <Typography variant="h4">{formatMessage(messages.promptHeading)}</Typography>
          <Typography variant="body1">{formatMessage(messages.prompt)}</Typography>
          <DynamicSearchField
            autofocus
            isolated
            postProcessSearchUrl={(url) => `${url}?${ONBOARDING_QUERY_PARAM}`}
            onNavigate={closeModal}
          />
        </Stack>
        <Stack padding={3} gap={3}>
          <Typography variant="h4">{formatMessage(messages.explanationHeading)}</Typography>
          <Typography variant="body1">{formatMessage(messages.explanation)}</Typography>
        </Stack>
        <Stack marginTop="auto" paddingX={3} alignItems="center">
          <Button
            variant="text"
            sx={{ textDecoration: 'underline', ':hover': { textDecoration: 'underline' } }}
            onClick={closeModal}
          >
            {formatMessage(messages.closeLink)}
          </Button>
        </Stack>
      </Stack>
    </DialogPreset>
  );
};
