import type { CacheExchangeOpts } from '@urql/exchange-graphcache';

export const keys: CacheExchangeOpts['keys'] = {
  AbsoluteDiscount: () => null,
  AbTesting: () => null,
  AdditionalFlag: () => null,
  AlternateLocalizedLink: () => null,
  APlus: () => null,
  AttributeValue: () => null,
  Availability: () => null,
  AvailabilityDescription: () => null,
  AvailableVariations: () => null,
  Bonuspoints: () => null,
  Basket: () => 'MyUniqueBasketId',
  BasketAPlus: () => null,
  BasketAvailability: () => null,
  BasketLineItem: () => null,
  BasketPowerEfficiencyFlag: () => null,
  BasketPriceSaving: () => null,
  BasketProduct: () => null,
  BasketProductPrice: () => null,
  BasketService: () => null,
  BasketServiceCluster: () => null,
  Brand: () => null,
  BrandRecommendation: () => null,
  Breadcrumb: () => null,
  BreadcrumbData: () => null,
  BreadcrumbItem: () => null,
  BreadcrumbSchema: () => null,
  CategoryFilterSelection: () => null,
  CategoryRecommendation: () => null,
  CategoryQueryResult: () => null,
  CategoryResult: () => null,
  CliplisterData: () => null,
  Cluster: () => null,
  CompleteVariationGroup: () => null,
  Countdown: () => null,
  CriteoPlacementBeacon: () => null,
  CriteoProductsResponse: () => null,
  CustomFlag: () => null,
  CustomerInformation: () => null,
  DefaultFilterSelection: () => null,
  Delivery: () => null,
  DeliveryData: () => null,
  Download: () => null,
  EcoLabel: () => null,
  EmptySearchResult: () => null,
  ExpressServiceData: () => null,
  FlatCategoryItem: () => null,
  GroupedFilterValue: () => null,
  Hint: () => null,
  InstallmentData: () => null,
  InstallmentValue: () => null,
  ItemListElement: () => null,
  LineItemExtraData: () => null,
  LoadbeeData: () => null,
  LoyaltyProgram: () => null,
  MediaAsset: () => null,
  NewTestAssignment: () => null,
  OocvRow: () => null,
  OocvTable: () => null,
  Offer: () => null,
  PercentDiscount: () => null,
  PowerEfficiencyFlag: () => null,
  PreviewIcon: () => null,
  PriceSaving: () => null,
  Product: (data) => data.sku as string,
  ProductAttributes: () => null,
  ProductAttributeType: () => null,
  ProductDimension: () => null,
  ProductDimensionRange: () => null,
  ProductDimensionValue: () => null,
  ProductPrice: () => null,
  ProductRecommendation: () => null,
  ProductResponse: () => null,
  ProductVariation: () => null,
  ProperCategoryQueryResult: () => null,
  PrudsysCategoryResponse: () => null,
  PrudsysCategoryToCategories: () => null,
  PrudsysProductResponse: () => null,
  RangeFilterSelection: () => null,
  RatingProduct: () => null,
  RatingResult: () => null,
  RatingReview: () => null,
  RatingReviewer: () => null,
  Ratings: () => null,
  RatingSummaryValues: () => null,
  RatingTag: () => null,
  RatingTags: () => null,
  RecipientInformationResponse: () => null,
  RecoBrand: () => null,
  RecoBrandResponse: () => null,
  RecoBrandTracking: () => null,
  RecoCategory: () => null,
  RecoCategoryTopsellerProduct: () => null,
  RecoCategoryTracking: () => null,
  RecoProductTracking: () => null,
  RecoProductTrackingUrl: () => null,
  ReviewStatistic: () => null,
  ReviewTag: () => null,
  ReviewTags: () => null,
  SearchDefaultFilter: () => null,
  SearchProductPrice: () => null,
  SearchProductResult: () => null,
  SearchQueryResult: () => null,
  SearchResultBase: () => null,
  SearchRedirectResult: () => null,
  SearchResultDiagnostics: () => null,
  SearchResultSummary: () => null,
  SearchRangeFilter: () => null,
  SearchTrackingInformation: () => null,
  SeoData: (data) => data.sku as string,
  SeoDataV2: () => null,
  SeoLink: () => null,
  SeoLinkV2: () => null,
  Service: () => null,
  SimpleProductPrice: () => null,
  SortingOrder: (data) => data.key as string,
  SponsoredProductData: () => null,
  SponsoredProductsTracking: () => null,
  SponsoredStylePreviewItem: () => null,
  StandardFlag: () => null,
  StyleBrand: () => null,
  StylePreviewItem: () => null,
  SuggestionPreview: () => null,
  SuggestionPreviewSearchResult: () => null,
  TireEfficiency: () => null,
  TireEfficiencyFlag: () => null,
  TopCategory: () => null,
  UnitPackage: () => null,
  Wishlist: () => 'MyUniqueWishlistId',
  WishlistLineItem: () => null,
};
