'use client';

import type { FC, ReactNode } from 'react';
import { useMemo, createContext } from 'react';
import Script from 'next/script';
import { useSearchParams } from 'next/navigation';

type TrackingProviderProps = {
  /** GTM id */
  tagmanagerId: string;
  /** gtm_auth value */
  auth?: string;
  /** gtm_preview value */
  preview?: string;
  /**
   * Name of the GTM datalayer page variable
   *
   * @default 'dataLayer'
   */
  dataLayerName?: string;
  /** domain for the Tagmanager script */
  domain?: string;
  /** React children */
  children: ReactNode;
  /** script src to overwrite full url */
  src?: string;
  /** flag if a bot user is present */
  isBot: boolean;
  disableGTM?: boolean;
};

type TrackingContextValue = {
  dataLayerName: string;
  isBot: boolean;
};

export const TrackingContext = createContext<TrackingContextValue | null>(null);

export const TrackingProvider: FC<TrackingProviderProps> = ({
  children,
  tagmanagerId,
  auth = '',
  preview = '',
  dataLayerName = 'dataLayer',
  domain = 'www.googletagmanager.com',
  src,
  isBot,
  disableGTM,
}) => {
  const searchParams = useSearchParams();

  const scriptUrl =
    typeof src !== 'undefined'
      ? src
      : `https://${domain}/gtm.js?id=${tagmanagerId}&l="+o+"${auth}${preview}&gtm_cookies_win=x`;

  const value = useMemo(() => ({ dataLayerName, isBot }), [dataLayerName, isBot]);
  const skipGTMSearchParam = searchParams?.get('skipGTM');
  const skipGTM = !!skipGTMSearchParam || !!disableGTM || !!process.env.NEXT_PUBLIC_SKIP_GTM;

  return (
    <TrackingContext.Provider value={value}>
      {children}
      {!skipGTM && (
        <Script
          id="gtm-script"
          data-testid="gtm-script"
          strategy="afterInteractive"
          dangerouslySetInnerHTML={{
            __html: `!function(t,e,n,o,a){t[o]=t[o]||[],t[o].push({"gtm.start":(new Date).getTime(),event:"gtm.js"});var d=e.getElementsByTagName(n)[0],m=e.createElement(n);m.async=!0,m.src="${scriptUrl}",m.onload=function(){m.onload=null,window.gmtLoaded=!0,window.gmtBlocked=!1;var t=document.createEvent("Event");t.initEvent("gtmLoaded",!1,!0),e.dispatchEvent(t)},m.onerror=function(){m.onerror=null,window.gmtLoaded=!0,window.gmtBlocked=!0;var t=document.createEvent("Event");t.initEvent("gtmLoaded",!1,!0),e.dispatchEvent(t)},d.parentNode.insertBefore(m,d)}(window,document,"script","${dataLayerName}");`,
          }}
        />
      )}
    </TrackingContext.Provider>
  );
};
