import { Box } from '@packages/shared/src/components/Box/Box';
import type { JSX } from 'react';
import { ComponentRecognizer } from '../../ComponentRecognizer/ComponentRecognizer';
import { registerComponents } from '../../ComponentRecognizer/componentRegistry';
import { ComponentTypes } from '../../../../interfaces';

import type { ParagraphType } from '../../../../interfaces';
import type { GroupType } from '../../CmsGroup/types';

export enum ImageGroupTestIds {
  main = 'image-group',
}

export const ImageGroup = ({ data }: { data: GroupType }): JSX.Element => {
  const {
    field_cells_per_row: cellsPerRowDesktop = data.paragraph ? data?.paragraph?.length || 1 : 0,
    field_cells_per_row_mobile: cellsPerRowMobile = (cellsPerRowDesktop &&
      Math.floor(cellsPerRowDesktop / 2)) ||
      1,
    field_col_gap: colGap = '0',
    field_row_gap: rowGap = '0',
    field_padding: padding = '0',
    field_max_width: maxWidth = 'auto',
    field_footer_layout_variant: layoutVariant = 'default',
  } = data;

  const cellsPerRowPercentageMobile = 100 / cellsPerRowMobile;
  const cellsPerRowPercentage = 100 / cellsPerRowDesktop;

  const flexBasisMob =
    colGap !== '0'
      ? `calc((100% - ${colGap} * (${cellsPerRowMobile} - 1)) / ${cellsPerRowMobile})`
      : `calc(100% / ${cellsPerRowMobile})`;

  const flexBasisDesktop =
    colGap !== '0'
      ? `calc((100% - ${colGap} * (${cellsPerRowDesktop} - 1)) / ${cellsPerRowDesktop})`
      : `calc(100% / ${cellsPerRowDesktop})`;

  return (
    <Box
      sx={(theme) => ({
        boxSizing: 'border-box',
        display: layoutVariant === 'inline' ? 'inline-flex' : 'flex',
        flexFlow: 'row wrap',
        justifyContent: 'flex-start',
        margin: 0,
        padding,
        '& .image': {
          maxWidth,
          flexDirection: cellsPerRowPercentageMobile === 100 ? 'column' : 'row',
          flexBasis: cellsPerRowMobile > 1 ? flexBasisMob : 'none',
          marginRight: cellsPerRowMobile === 1 ? 'auto' : colGap,
          marginBottom: cellsPerRowMobile === 1 ? rowGap : 'auto',
          [`&:nth-of-type(${cellsPerRowMobile}n+0)`]: {
            marginRight: 0,
          },
          [theme.breakpoints.up('md')]: {
            flexDirection: cellsPerRowPercentage === 100 ? 'column' : 'row',
            flexBasis: cellsPerRowMobile > 1 ? flexBasisDesktop : 'none',
            marginRight: cellsPerRowDesktop === 1 ? 'auto' : colGap,
            marginBottom: rowGap,
            [`&:nth-of-type(${cellsPerRowDesktop}n+0)`]: {
              marginRight: 0,
            },
          },
        },
      })}
      data-testid={ImageGroupTestIds.main}
      className={ImageGroupTestIds.main}
    >
      {data.paragraph &&
        data.paragraph.map((item: ParagraphType, index: number) => (
          // eslint-disable-next-line react/no-array-index-key
          <ComponentRecognizer key={`${ImageGroupTestIds.main}-item-${index}`} item={item} />
        ))}
    </Box>
  );
};

// Needed to run this component in Storybook and Jest tests. Register the component in a global componentRegistry object
registerComponents({ [ComponentTypes.FooterGroupImage]: ImageGroup });
