/* istanbul ignore file */
import type { AnyVariables, UseQueryArgs } from 'urql';
import { useQuery } from 'urql';
import { useMemo } from 'react';
import { useSession } from '../useSession/useSession';

// improve possibility for passing options if needed
export const useAuthQuery = <Data = any, Variables extends AnyVariables = AnyVariables>({
  query,
  variables,
  requestPolicy,
  pause,
}: UseQueryArgs<Variables, Data>) => {
  const { jwtToken } = useSession();
  return useQuery({
    query,
    variables,
    requestPolicy: requestPolicy || 'cache-first',
    context: useMemo(
      () => ({ suspense: false, fetchOptions: { headers: { 'x-ec-token': jwtToken! } } }),
      [jwtToken],
    ),
    pause: !jwtToken || pause,
  });
};
