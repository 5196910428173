import DOMPurify from '@packages/config/utils/dompurify';
import { Dialog } from '@packages/shared/src/components/Dialog';
import { Button } from '@packages/shared/src/components/Button';
import { Box } from '@packages/shared/src/components/Box';
import { Link } from '@packages/shared/src/components/Link';
import { useState, type JSX } from 'react';
import { useCookies } from '@packages/shared/src/providers/CookieProvider/CookieProvider';
import { SurveyStorage } from './types';
import type { SurveyModalType } from './types';
import { shouldShowModal } from './utils/shouldShowModal';
import { useAssignLinkTargetBasedOnBotStatus } from '../../hooks/useAssignLinkTargetBasedOnBotStatus';

/**
 * SurveyModal component to display survey modals.
 *
 * @param {SurveyType} props.data - The survey data
 * @param {boolean} props.isPreview - Whether the survey is in preview mode
 * @returns {JSX.Element|null} The rendered SurveyModal component or null if conditions are not met
 */
export const SurveyModal = ({ data, isPreview }: SurveyModalType): JSX.Element | null => {
  const {
    field_display_second_page: showOnSecondPage = false,
    field_display_ratio: displayRatio = '100',
    field_survey_button_link: buttonLink,
    field_survey_button_text: buttonText,
    field_survey_headline: headline = '',
    field_survey_text: textContent,
    id: surveyId,
  } = data;

  const { setCookie, getCookies } = useCookies();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(true);
  const cookieOptions = {
    maxAge: 30 * 24 * 60 * 60 * 1000, // 30 days of cookie lifetime,
    path: '/',
  };
  const surveyStorageName = `${SurveyStorage.name}-${surveyId}`;

  const updateStoredData = (dataToUpdate: Record<string, any>) => {
    const existingData = JSON.parse(getCookies()[surveyStorageName] || '{}');
    const updatedData = { ...existingData, ...dataToUpdate };

    setCookie(null, surveyStorageName, JSON.stringify(updatedData), cookieOptions);
  };

  const assignLinkTarget = useAssignLinkTargetBasedOnBotStatus();
  const finalLink = assignLinkTarget(buttonLink ?? '');

  const handleClose = () => {
    setIsModalOpen(false);
    updateStoredData({ [SurveyStorage.statusClosed]: Date.now() });
  };

  const displayModal = shouldShowModal({
    surveyStorageName,
    showOnSecondPage,
    displayRatio: parseInt(displayRatio, 10),
    isPreview,
    getCookies,
    setCookie,
  });

  if (textContent && textContent.processed && displayModal) {
    return (
      <Dialog
        id="survey-modal"
        open={isModalOpen}
        handleClose={handleClose}
        title={headline}
        dialogActions={
          <Button
            onClick={handleClose}
            LinkComponent={Link}
            href={finalLink}
            target="_blank"
            color="primary"
          >
            {buttonText}
          </Button>
        }
        dialogContent={
          <Box
            dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(textContent.processed) }}
            p={3}
          />
        }
      />
    );
  }

  return null;
};
