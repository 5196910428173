import { useTrackCampaign } from '@packages/shared/src/hooks/useTrackCampaign/useTrackCampaign';
import { useRef } from 'react';
import type { ButtonProps } from '@packages/shared/src/components/Button/Button';
import { Button } from '@packages/shared/src/components/Button/Button';
import { Link } from '@packages/shared/src/components/Link/Link';
import { useTheme } from '@mui/material/styles';
import {
  ArrowLargeLeft32,
  ArrowLargeRight32,
  ArrowMediumLeft32,
  ArrowMediumRight32,
  ArrowMiniLeft32,
  ArrowMiniRight32,
} from '@packages/themes/icons';
import { buildLinkWithArguments } from '../../utils/buildLinkWithArguments';
import type { LinkByButtonType, Icon } from './types';
import { SoftHyphenInjector } from '../SoftHyphenInjector';
import { useAssignLinkTargetBasedOnBotStatus } from '../../hooks/useAssignLinkTargetBasedOnBotStatus';
import { registerComponents } from '../ComponentRecognizer/componentRegistry';
import { ComponentTypes } from '../../../interfaces';
import type { DrupalIconTypes, Size } from '../../../interfaces';

const getIcon = (icon: Icon, size: Size) => {
  const icons = {
    ArrowRight: {
      small: <ArrowMiniRight32 />,
      medium: <ArrowMediumRight32 />,
      large: <ArrowLargeRight32 />,
    },
    ArrowLeft: {
      small: <ArrowMiniLeft32 />,
      medium: <ArrowMediumLeft32 />,
      large: <ArrowLargeLeft32 />,
    },
  };

  return icons[icon][size];
};

export const LinkByButton = ({
  data,
  icon,
  className,
}: {
  data: LinkByButtonType;
  icon?: DrupalIconTypes;
  className?: string;
}) => {
  const {
    field_link: link,
    field_text: text,
    field_layout: color,
    behavior_paragraph_layout: behaviorParagraphLayout,
    behavior_paragraph_size: behaviorSize,
    field_link_target: linkTarget,
  } = data;

  const finalColor = (behaviorParagraphLayout || color) as ButtonProps['color'];

  const iconElement = icon && behaviorSize && getIcon(icon, behaviorSize);
  const iconPosition = icon && icon.startsWith('ArrowRight') ? 'endIcon' : 'startIcon';

  const tmpLink = buildLinkWithArguments(link, data);
  const assignLinkTarget = useAssignLinkTargetBasedOnBotStatus();
  const finalLink = assignLinkTarget(tmpLink);
  const buttonRef = useRef<HTMLButtonElement>(null);
  const { trackClick } = useTrackCampaign(data, buttonRef, finalLink);
  const { palette } = useTheme();

  return (
    <Button
      {...(className && { className })}
      ref={buttonRef}
      LinkComponent={Link}
      href={finalLink}
      // runtime-check if value is in type values
      {...(finalColor && Object.keys(palette).includes(finalColor) && { color: finalColor })}
      {...(behaviorSize && {
        size: behaviorSize,
      })}
      {...(icon && iconElement && { [iconPosition]: iconElement })}
      {...(linkTarget && linkTarget === '_blank' && { target: linkTarget })}
      onClick={() => trackClick()}
    >
      <SoftHyphenInjector text={text} />
    </Button>
  );
};

// Needed to run this component in Storybook and Jest tests. Register the component in a global componentRegistry object
registerComponents({ [ComponentTypes.LinkByButton]: LinkByButton });
