/* eslint-disable react/destructuring-assignment */
import Image from 'next/image';
import { useIntl } from 'react-intl';
import { useConfig } from '@packages/shared/src/hooks/useConfig/useConfig';
import { powerSupplyMessages } from '../PowerSupply/messages';
import type { PowerSupplyProps } from '../PowerSupply/PowerSupplyProps';

const width = 68;
const height = 93;

export type PowerSupplyPictogramProps =
  | {
      layout: 'NotIncluded' | 'Included';
    }
  | ({
      layout: 'Specification';
    } & Pick<PowerSupplyProps, 'maxPower' | 'minPower' | 'chargerType'>);

export const PowerSupplyPictogram = (props: PowerSupplyPictogramProps) => {
  const { formatMessage } = useIntl();
  const {
    product: {
      powerSupply: {
        pictogram: { included, notIncluded, specification },
      },
    },
  } = useConfig();

  const wrapperStyles = { width: `${width}px`, height: `${height}px` };

  if (props.layout === 'Included' || props.layout === 'NotIncluded') {
    const { layout, ...rest } = props;
    return (
      <div {...rest} style={wrapperStyles}>
        <Image
          alt={formatMessage(
            powerSupplyMessages[layout === 'Included' ? 'included' : 'notIncluded'],
          )}
          src={layout === 'Included' ? included : notIncluded}
          width={width}
          height={height}
          unoptimized
        />
      </div>
    );
  }
  if (props.layout === 'Specification') {
    const { maxPower, minPower, chargerType, layout, ...rest } = props;

    return (
      <div {...rest} style={wrapperStyles} className="relative">
        <Image
          alt={`${minPower} - ${maxPower} W${chargerType === 'PowerDelivery' ? ' USB PD' : ''}`}
          src={specification}
          width={width}
          height={height}
          unoptimized
        />
        <div className="font-family-[sans-serif] absolute bottom-1.5 left-1.5 h-[44px] w-[44px] text-nowrap bg-[#fff] text-center text-[12px] font-bold [&>div]:absolute [&>div]:left-0 [&>div]:w-full [&>div]:overflow-hidden">
          <div className="top-0">
            {minPower}-{maxPower}
          </div>
          <div className="top-[14px]">W</div>
          {chargerType === 'PowerDelivery' && <div className="top-[28px] text-[8px]">USB PD</div>}
        </div>
      </div>
    );
  }

  return null;
};
