import { SvgIcon, SvgIconProps } from "@mui/material";
import { memo } from "react";
const SvgWarningCircle32 = (props: SvgIconProps) => {
  const {
    sx,
    ...other
  } = props;
  return <SvgIcon xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 32 32" sx={{
    ...sx
  }} {...other}><circle cx={16} cy={16} r={16} fill="#F5A623" /><path fill="#fff" d="M14.333 23.333h3.334V20h-3.334zm0-6h3.334V9h-3.334v8.334z" /></SvgIcon>;
};
const Memo = memo(SvgWarningCircle32);
export default Memo;