import type { FC } from 'react';

import { Star32 } from '@packages/themes/icons';

import { defineMessages, useIntl } from 'react-intl';
import { Typography } from '../Typography';
import { Box } from '../Box';

const messages = defineMessages({
  averageRating: {
    id: 'productCard.averageRating',
    defaultMessage: 'Durchschnittliche Bewertung: {rating}',
  },
  reviewCount: {
    id: 'productCard.reviewCount',
    defaultMessage: '{count} Bewertungen',
  },
});

export type RatingDisplayProps = {
  /**
   * Average rating for a given product, ranging from 0 to 5 with a precision of 0.1.
   * */
  avgRating: number;
  /**
   * Amount of given ratings.
   * */
  reviewCount?: number;
};

/**
 * Display a star rating between 0 and 5, prioritizing performance and efficiency
 * */
export const RatingDisplay: FC<RatingDisplayProps> = ({ avgRating, reviewCount }) => {
  const { formatMessage } = useIntl();

  // round to 2 decimal digits, like with the Rating component
  const roundedRating = Math.round(avgRating * 10) / 10;
  // normalize to range [0, 1]
  const normalizedRating = roundedRating / 5;
  // gaps between stars should be ignored when it comes to filling the stars
  const filledGaps = Math.floor(roundedRating);

  // messages are each used twice, we only need to format them once
  const ratingMessage = formatMessage(messages.averageRating, { rating: roundedRating });
  const reviewCountMessage = formatMessage(messages.reviewCount, { count: reviewCount });

  return (
    <Box display="flex" alignItems="center" sx={{ '--gap': (theme) => theme.spacing(0.2) }}>
      <Box
        display="flex"
        gap="var(--gap)"
        title={ratingMessage}
        role="img"
        aria-label={ratingMessage}
        position="relative"
        sx={{
          '&::after': {
            content: '""',
            position: 'absolute',
            top: 0,
            // gaps are ignored for scaling/positioning the "fill" overlay
            left: `calc(${normalizedRating} * (100% - 4 * var(--gap)) + ${filledGaps} * var(--gap))`,
            bottom: 0,
            right: 0,
            backgroundColor: 'rgba(255, 255, 255, 0.75)',
          },
        }}
        fontSize="16px"
      >
        <Star32 fontSize="inherit" />
        <Star32 fontSize="inherit" />
        <Star32 fontSize="inherit" />
        <Star32 fontSize="inherit" />
        <Star32 fontSize="inherit" />
      </Box>
      {reviewCount !== undefined && reviewCount > 0 && (
        <Typography
          variant="body3"
          color="textDark"
          title={reviewCountMessage}
          aria-label={reviewCountMessage}
        >
          ({reviewCount})
        </Typography>
      )}
    </Box>
  );
};
