import { Box } from '@packages/shared/src/components/Box/Box';
import { Button } from '@packages/shared/src/components/Button/Button';
import { Typography } from '@packages/shared/src/components/Typography/Typography';
import { Notification } from '@packages/shared/src/components/Notification/Notification';
import { useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';

const previewMessages = defineMessages({
  errorClose: {
    id: 'preview.banner.error.close',
    defaultMessage: 'Der Tab konnte nicht geschlossen werden. Bitte schließen Sie ihn manuell.',
  },
  title: {
    id: 'preview.banner.title',
    defaultMessage: 'Vorschau',
  },
  buttonClose: {
    id: 'preview.banner.button.close',
    defaultMessage: 'Vorschau schließen',
  },
});

/**
 * This component displays a header for content that is previewed from drupal
 *
 * @returns Preview header component
 */
export const PreviewHeaderContainer = () => {
  const [openError, setOpenError] = useState(false);
  const { formatMessage } = useIntl();
  return (
    <>
      <Notification severity="error" open={openError}>
        {formatMessage(previewMessages.errorClose)}
      </Notification>
      <Box
        sx={{
          display: 'flex',
          flex: 'column',
          justifyContent: 'space-between',
          alignItems: 'center',
          paddingInline: 4,
          paddingBlock: 2,
          backgroundColor: 'additional.light',
          borderLeft: 'solid 0.375rem',
          borderLeftColor: 'additional.dark',
          mb: 2,
        }}
      >
        <Typography variant="h4">{formatMessage(previewMessages.title)}</Typography>
        <Button
          sx={{
            textTransform: 'uppercase',
            backgroundColor: 'grey.light',
          }}
          variant="outlined"
          onClick={() => {
            /**
             * Note that window.close() only works if the tab/window is a fresh new instance and did not
             * have any other pages opened before (not even browser specific pages like a firefox start page).
             *
             * For example pasting the URL into an active tab and then clicking the close button
             * will lead to the error case.
             */
            window.close();
            if (!window.closed) setOpenError(true);
          }}
        >
          {formatMessage(previewMessages.buttonClose)}
        </Button>
      </Box>
    </>
  );
};
