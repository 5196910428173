import { DynamicYield } from '@packages/shared/src/components/DynamicYield/DynamicYield';
import type { DynamicYieldType } from './types';
import { registerComponents } from '../ComponentRecognizer/componentRegistry';
import { ComponentTypes } from '../../../interfaces';

export const CmsDynamicYield = ({ data }: { data: DynamicYieldType }) => (
  <DynamicYield
    dataDyId={data.field_custom_id}
    margin={data.field_margin}
    padding={data.field_padding}
  />
);

// Needed to run this component in Storybook and Jest tests. Register the component in a global componentRegistry object
registerComponents({ [ComponentTypes.DynamicYield]: CmsDynamicYield });
