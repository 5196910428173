import { useConfig } from '@packages/shared/src/hooks/useConfig/useConfig';
import {
  filterTimeControl,
  getLocalTime,
} from '@packages/shared/src/utils/filterTimeControl/filterTimeControl';
import { useSearchParams } from 'next/navigation';
import type { ParagraphType, TimeControl } from '../../interfaces';

export const useFilterTimeControl = (
  testDate: string | string[] | undefined | null = undefined,
): ((item: TimeControl | ParagraphType) => boolean) => {
  const query = Object.fromEntries(useSearchParams()?.entries() ?? []);
  const { TestDate = testDate } = query;
  const { officeTimes, timeZone } = useConfig();

  const currentLocalTime = getLocalTime(timeZone);

  return (item: TimeControl | ParagraphType): boolean => {
    if (
      'field_valid_from' in item ||
      'field_valid_to' in item ||
      'field_visible_from' in item ||
      'field_visible_until' in item ||
      'behavior_paragraph_time_control_valid_from' in item ||
      'behavior_paragraph_time_control_valid_until' in item ||
      'behavior_paragraph_visibility_officehours' in item
    ) {
      return filterTimeControl(
        item,
        TestDate || currentLocalTime?.toISOString(),
        officeTimes,
        timeZone,
      );
    }
    // if there is no date fields at all, we always want to display it and return true
    return true;
  };
};
