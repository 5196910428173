import { useTracking } from '@packages/tracking/src/hooks/useTracking/useTracking';
import { useConfig } from '@packages/shared/src/hooks/useConfig/useConfig';

import type { JSX } from 'react';

export const Sovendus = ({
  type = 'default',
  containerId = 'sovendus-container-1',
}: {
  type?: 'default' | 'newsletterSuccess';
  containerId?: string;
}): JSX.Element | null => {
  const {
    sovendus: { enabled },
  } = useConfig();

  const dispatchGTMEvent = useTracking();

  if (!enabled) {
    return null;
  }

  dispatchGTMEvent({
    event: 'renderSovendusScript',
    sovendusType: type,
    sovendusContainerId: containerId,
  });

  return <div id={containerId} />;
};
