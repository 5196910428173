import type { RefObject } from 'react';
import { useRef, useEffect, useState } from 'react';

interface Config extends IntersectionObserverInit {
  // When true, triggers only once on intersecting
  intersectOnce?: boolean;
  // For avoid a rerender use the callback
  callback?: (observerEntry: IntersectionObserverEntry['isIntersecting'] | undefined) => void;
}

/**
 * @param elementRef element to observe
 * @param param1 observer configuration
 * @returns observer entry
 */
export const useIntersectionObserver = (
  elementRef: RefObject<Element | null>,
  {
    threshold = 0,
    root = null,
    rootMargin = '0%',
    intersectOnce = true,
    callback = undefined,
  }: Config,
): {
  observerEntry: IntersectionObserverEntry | undefined;
  isIntersecting: IntersectionObserverEntry['isIntersecting'];
} => {
  const [observerEntry, setObserverEntry] = useState<IntersectionObserverEntry>();
  const observerRef = useRef<IntersectionObserver | null>(null);

  const updateEntry = ([entry]: IntersectionObserverEntry[]): void => {
    if (callback) {
      callback(entry.isIntersecting);
    } else {
      setObserverEntry(entry);
    }
    if (intersectOnce && entry?.isIntersecting) {
      observerRef.current?.disconnect();
    }
  };

  useEffect(() => {
    const node = elementRef?.current; // given element ref
    const hasIOSupport = !!window.IntersectionObserver; // IntersectionObeserver browser support

    if (!hasIOSupport || !node) return () => {};

    observerRef.current = new IntersectionObserver(updateEntry, { threshold, root, rootMargin });
    observerRef.current.observe(node);

    return () => observerRef.current?.disconnect();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { observerEntry, isIntersecting: !!observerEntry?.isIntersecting };
};
