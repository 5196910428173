'use client';

import { defineMessage, useIntl } from 'react-intl';

import { Link } from '@packages/shared';
import type { GenericTrackingEvent } from '@packages/tracking/src/types/events';
import { useTracking } from '@packages/tracking/src/hooks/useTracking/useTracking';

const logoTitle = defineMessage({
  id: 'logo.title',
  defaultMessage: 'zur Startseite',
});
/** Header tenant logo. Links to home */
export const LinkedLogo = () => {
  const { formatMessage } = useIntl();

  const dispatchGtmEvent = useTracking();

  const trackLogoClick = () => {
    dispatchGtmEvent<GenericTrackingEvent>({
      event: 'suche-event',
      eventValue: 'homepage=storefront',
    });
  };

  return (
    <Link
      aria-label={formatMessage(logoTitle)}
      href="/"
      noLinkStyle
      title={formatMessage(logoTitle)}
      onClick={trackLogoClick}
    >
      <div className="page-logo icon-emp-logo relative align-middle" />
    </Link>
  );
};
