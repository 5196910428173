import { useDeviceType } from '@packages/shared/src/hooks/useDeviceType/useDeviceType';
import { useTrackCampaign } from '@packages/shared/src/hooks/useTrackCampaign/useTrackCampaign';
import Image from 'next/image';
import { useConfig } from '@packages/shared/src/hooks/useConfig/useConfig';
import { useRef } from 'react';
import { ConditionalWrapper } from '@packages/shared/src/components/ConditionalWrapper/ConditionalWrapper';
import { Link } from '@packages/shared/src/components/Link/Link';
import { useAssignLinkTargetBasedOnBotStatus } from '../../hooks/useAssignLinkTargetBasedOnBotStatus';
import type { ImageTeaserProps } from './types';

/**
 * The ImageTeaser component displays the images and provides a scalable image experience
 * by adjusting the displayed image according to the device type (mobile, tablet, desktop).
 * It supports image optimization, lazy loading, and custom alt and title attributes for SEO.
 * The component also includes link wrapping with tracking capabilities for campaign analytics.
 *
 * @param {ImageTeaserType} data - The data object containing image URLs for various device types and SEO fields.
 * @param {string} link - The URL to which the image will link.
 * @returns A React component that conditionally renders an optimized image or a standard img element wrapped in a link.
 */
export const ImageTeaser = ({
  data,
  link = '',
  linkTarget,
  isLazyLoading = false,
}: ImageTeaserProps) => {
  const {
    // image
    image_default: imageDefault,
    image_mobile: imageMobile,
    image_tablet: imageTablet,
    // seo
    field_alternate_text: imageAlt,
  } = data;

  const { isMobile, isTablet } = useDeviceType();

  const {
    assets: {
      cmsImage: {
        imageOptimizationEnabled,
        uriTransformSource,
        uriTransformEndpoint,
        imageQuality,
      },
    },
  } = useConfig();

  const imageData = (isMobile && imageMobile) || (isTablet && imageTablet) || imageDefault;
  const imageDataWidth = !Array.isArray(imageData?.data) && imageData?.data?.width;
  const imageDataHeight = !Array.isArray(imageData?.data) && imageData?.data?.height;
  const useNextImage = imageData?.url && imageDataWidth && imageDataHeight;
  const imgref = useRef<HTMLImageElement>(null);
  const { trackClick } = useTrackCampaign(data, imgref, link);
  const assignLinkTarget = useAssignLinkTargetBasedOnBotStatus();
  const finalLink = assignLinkTarget(link);

  if (!imageData?.url) {
    return null;
  }

  return (
    <ConditionalWrapper
      condition={finalLink !== ''}
      wrapper={(ch) => (
        <Link
          href={finalLink}
          noLinkStyle
          onClick={() => trackClick()}
          openInNewTab={!!(linkTarget && linkTarget === '_blank')}
        >
          {ch}
        </Link>
      )}
    >
      {useNextImage ? (
        <Image
          ref={imgref}
          src={
            imageOptimizationEnabled && typeof imageData?.uri === 'string'
              ? imageData.uri.replace(uriTransformSource, uriTransformEndpoint)
              : imageData.url
          }
          alt={imageAlt || ''}
          width={parseInt(imageDataWidth, 10)}
          height={parseInt(imageDataHeight, 10)}
          loading={isLazyLoading ? 'lazy' : 'eager'}
          {...(imageOptimizationEnabled ? { quality: imageQuality } : { unoptimized: true })}
        />
      ) : (
        // eslint-disable-next-line @next/next/no-img-element
        <img
          ref={imgref}
          src={imageData?.url}
          alt={imageAlt || ''}
          loading={isLazyLoading ? 'lazy' : 'eager'}
        />
      )}
    </ConditionalWrapper>
  );
};
