/* eslint-disable @typescript-eslint/naming-convention, @next/next/no-img-element */
import { useEffect, useRef } from 'react';
import Image from 'next/image';
import { useDeviceType } from '@packages/shared/src/hooks/useDeviceType/useDeviceType';
import { useConfig } from '@packages/shared/src/hooks/useConfig/useConfig';
import { useTracking } from '@packages/tracking/src/hooks/useTracking/useTracking';
import {
  useTrackCampaign,
  useTrackCampaignOnLoad,
} from '@packages/shared/src/hooks/useTrackCampaign/useTrackCampaign';
import { Box } from '@packages/shared/src/components/Box/Box';
import { ConditionalWrapper } from '@packages/shared/src/components/ConditionalWrapper/ConditionalWrapper';
import { Link } from '@packages/shared/src/components/Link/Link';
import { styleJustifyContent } from './utils/styleHelpers';
import type { CmsImageType } from './types';
import { useAssignLinkTargetBasedOnBotStatus } from '../../hooks/useAssignLinkTargetBasedOnBotStatus';
import { GroupOnImage } from './GroupOnImage';
import { onLoad, waitUntilReady } from '../../utils/onLoadTracking';
import { buildLinkWithArguments } from '../../utils/buildLinkWithArguments';

export type CmsImageItemProps = {
  data: CmsImageType;
};

export const CmsImageItem = ({ data }: CmsImageItemProps) => {
  const { isMobile } = useDeviceType();
  const {
    assets: {
      cmsImage: {
        imageOptimizationEnabled,
        uriTransformSource,
        uriTransformEndpoint,
        imageQuality,
      },
    },
  } = useConfig();

  const {
    field_display_variant: fieldDisplayVariant,
    field_alternate_text: altText = '',
    field_image_size_type: fieldImageSizeType = 'max-width',
    field_alignment: fieldAlignment = 'center',
    field_lazyload: lazyLoading = true,
    field_onload_tracking_name: onloadTrackingName,
    field_onload_tracking_key: onloadTrackingKey,
    field_onload_tracking_value: onloadTrackingValue,
    field_link: fieldLink,
    field_link_target: fieldLinkTarget,
    field_tracking,
    field_link_anchor,
    field_link_highlight,
    field_link_searchfilter,
    field_bonus_number,
    paragraph,
    trackOnLoad,
    behavior_paragraph_borderRadius: borderRadius,
  } = data;

  const dispatchGTMEvent = useTracking();
  const imgref = useRef<HTMLImageElement>(null);

  /**
   * If tracking params are set, fire the event once the image is loaded (complete) and
   * the dataLayer is ready
   */
  useEffect(() => {
    if (onloadTrackingName && onloadTrackingKey && onloadTrackingValue) {
      waitUntilReady(
        () => (window as any).dataLayer && imgref.current?.complete,
        () =>
          onLoad('trackFooterImageLoad', dispatchGTMEvent, {
            onloadTrackingName,
            onloadTrackingKey,
            onloadTrackingValue,
          }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatchGTMEvent]);

  const imageObjDefault =
    data?.file?.find(
      (file) => file.field_name === 'field_image_default' || file.field_name === 'field_svg_file',
    ) ??
    data?.image_default ??
    null;

  const imageObjMobile =
    data?.file?.find((file) => file.field_name === 'field_image_mobile') ??
    data?.image_mobile ??
    null;

  const isVectorImage = data?.file?.some((file) => file.filemime === 'image/svg+xml');

  const getImageData = () => {
    if (isMobile && imageObjMobile) {
      return imageObjMobile;
    }
    return imageObjDefault;
  };

  const imageData = getImageData();
  const imageDataWidth = !Array.isArray(imageData?.data) && imageData?.data?.width;
  const imageDataHeight = !Array.isArray(imageData?.data) && imageData?.data?.height;
  const useNextImage = !isVectorImage && imageData?.url && imageDataWidth && imageDataHeight;
  const tmpLink = buildLinkWithArguments(fieldLink, {
    field_tracking,
    field_link_anchor,
    field_link_highlight,
    field_link_searchfilter,
    field_bonus_number,
  });

  const assignLinkTarget = useAssignLinkTargetBasedOnBotStatus();
  const finalLink = assignLinkTarget(tmpLink);

  const { trackDisplay, trackClick: trackClickOnLoad } = useTrackCampaignOnLoad(data, finalLink);
  const { trackClick: trackClickOnIntersect } = useTrackCampaign(
    data,
    imgref,
    finalLink,
    trackOnLoad,
  );
  const trackClick = trackOnLoad ? trackClickOnLoad : trackClickOnIntersect;

  useEffect(() => {
    if (trackOnLoad) {
      trackDisplay();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box
      component="span"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: styleJustifyContent(fieldAlignment),
        position: 'relative',
        width: '100%',
        '& img': {
          height: 'auto !important', // is needed to avoid white space above and below the images.
          ...(isVectorImage && {
            display: 'block',
            width: '100%',
          }),
          ...(fieldImageSizeType === 'full-width' && {
            width: '100%',
          }),
          maxWidth: fieldImageSizeType === 'full-width' || isVectorImage ? 'auto' : '100%',
          borderRadius: fieldDisplayVariant === 'circle' ? '50%' : borderRadius,
        },
      }}
    >
      <ConditionalWrapper
        condition={!!fieldLink}
        wrapper={(ch) => (
          <Link
            href={finalLink}
            {...(fieldLinkTarget && { target: fieldLinkTarget })}
            noLinkStyle
            onClick={() => trackClick()}
            // INSPIRE-3356 - prevent gap between images in a group
            sx={{ display: 'inherit' }}
          >
            {ch}
          </Link>
        )}
      >
        <>
          {useNextImage && imageData?.url && (
            <Image
              ref={imgref}
              src={
                imageOptimizationEnabled && typeof imageData?.uri === 'string'
                  ? imageData.uri.replace(uriTransformSource, uriTransformEndpoint)
                  : imageData.url
              }
              alt={altText || ''}
              width={parseInt(imageDataWidth, 10)}
              height={parseInt(imageDataHeight, 10)}
              style={{ objectFit: 'contain' }}
              {...(lazyLoading ? { loading: 'lazy' } : { priority: true })}
              {...(imageOptimizationEnabled ? { quality: imageQuality } : { unoptimized: true })}
            />
          )}

          {!useNextImage && imageData?.url && (
            <img
              ref={imgref}
              src={imageData?.url}
              alt={altText || ''}
              loading={lazyLoading ? 'lazy' : 'eager'}
              /* This case can never occur because the next/image component is rendered as soon as width, height and url are available. */
              /* {...(imageDataWidth && { width: imageDataWidth })} */
              /* {...(imageDataHeight && { height: imageDataHeight })} */
            />
          )}
        </>
      </ConditionalWrapper>

      {paragraph && <GroupOnImage paragraph={paragraph} />}
    </Box>
  );
};
