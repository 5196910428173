import config from '@packages/config';
import type { Locale } from '@packages/config/src/default';
import { Box } from '@packages/shared/src/components/Box/Box';
import { Button } from '@packages/shared/src/components/Button/Button';
import { Link } from '@packages/shared/src/components/Link/Link';
import { useCookieConsent } from '@packages/shared/src/hooks/useCookieConsent/useCookieConsent';
import { useState, type JSX } from 'react';
import { defineMessages, useIntl } from 'react-intl';

const Messages = defineMessages({
  link: {
    id: 'video.overlay.description.link.label',
    defaultMessage: 'Datenschutzerklärung',
  },
  headline: {
    id: 'video.overlay.headline',
    defaultMessage: '{providerName}-Inhalte aktivieren',
  },
  description: {
    id: 'video.overlay.description',
    defaultMessage: `Um dein Einkaufserlebnis so abwechslungsreich wie möglich zu gestalten, 
      zeigen wir in unserem Onlineshop auch {providerName}-Videos. Damit du sie 
      ansehen kannst, brauchen wir deine Einwilligung. Denn deine personenbezogenen 
      Daten werden dabei zur Analyse an {provider} weitergegeben. 
      Alle Infos findest du in unserer {link}.`,
  },
  button: {
    id: 'video.overlay.button.label',
    defaultMessage: 'INHALTE AKTIVIEREN',
  },
});

const Overlay = ({
  onClickActivateContent,
  texts: { provider, providerName },
}: {
  onClickActivateContent: Function;
  texts: { provider?: string; providerName: string };
}) => {
  const intl = useIntl();
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        overflow: 'auto',
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        minHeight: { xs: 20, md: 30 },
        minWidth: { xs: 40, md: 'initial' },
        backgroundColor: 'grey.light',
      }}
    >
      <Box
        sx={{
          paddingInline: 4,
          fontWeight: 'bold',
          color: 'primary.contrastText',
          backgroundColor: 'primary.main',
          paddingBlock: 1.1,
        }}
      >
        {intl.formatMessage(Messages.headline, { providerName })}
      </Box>
      <Box
        sx={{
          paddingInline: 4,
          paddingBlock: 2,
          mb: 2,
          overflow: 'auto',
        }}
      >
        {intl.formatMessage(Messages.description, {
          providerName,
          // it is optional to use another provider label in text content
          provider: provider || providerName,
          link: (
            <Link
              href={config.tracking.schemaOrgOrganisation[intl.locale as Locale].privacyNoticePath}
              title={intl.formatMessage(Messages.link)}
            >
              {intl.formatMessage(Messages.link)}
            </Link>
          ),
        })}
      </Box>
      <Box sx={{ marginBottom: 1, paddingInline: 4, alignSelf: 'center', flexGrow: 1 }}>
        <Button onClick={() => onClickActivateContent()} color="primary">
          {intl.formatMessage(Messages.button)}
        </Button>
      </Box>
    </Box>
  );
};

export const VideoConsentOverlay = ({
  children,
  sessionStorageKey,
  texts,
}: {
  children: JSX.Element;
  sessionStorageKey: string;
  texts: {
    provider?: string;
    providerName: string;
  };
}) => {
  const isAllowed: boolean = useCookieConsent('C0004');
  // customize storage key to prevent duplicated keys
  const overlayStorageKey = `videoConsentOverlay-${sessionStorageKey}`;
  const [overlayVisible, setOverlayVisible] = useState(
    typeof window !== 'undefined'
      ? !(sessionStorage.getItem(overlayStorageKey) === '1') && !isAllowed
      : true, // always render overlay on serverside
  );

  const onClickActivateContent = () => {
    sessionStorage.setItem(overlayStorageKey, '1');
    setOverlayVisible(false);
  };

  return overlayVisible ? (
    <Overlay onClickActivateContent={onClickActivateContent} texts={texts} />
  ) : (
    children
  );
};
