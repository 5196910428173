import type { FC } from 'react';
import { DynamicSearchField } from '../../Header/Toolbar/SearchField/SearchField.dynamic';
import { ONBOARDING_QUERY_PARAM } from '../../Header/useInspiringSearchSettings.shared';
import { useInspiringSearchSettings } from '../../Header/useInspiringSearchSettings';
import { InspiringSearchTeaser } from './InspiringSearchTeaser';

export const InspiringSearchTeaserWithAbTesting: FC = () => {
  const { isInspiringSearchEnabled } = useInspiringSearchSettings();

  return isInspiringSearchEnabled ? (
    <InspiringSearchTeaser
      SearchField={
        <DynamicSearchField
          isolated
          postProcessSearchUrl={(url) => `${url}?${ONBOARDING_QUERY_PARAM}`}
        />
      }
    />
  ) : null;
};
