import type { TooltipDataContentSnippetType, TooltipDataPromotionType } from '../../interfaces';
import { useFilterTimeControl } from './useFilterTimeControl';

export const useGetTooltipContent = (
  promotion?: TooltipDataPromotionType,
  contentSnippet?: TooltipDataContentSnippetType,
) => {
  const filterTimeControl = useFilterTimeControl();

  let tooltipContentSnippet = null;
  if (contentSnippet) {
    if (Array.isArray(contentSnippet)) {
      tooltipContentSnippet = contentSnippet?.find(
        (item) => item.bundle === 'tooltip' && item.field_conditions,
      )?.field_conditions;
    } else if (
      contentSnippet.field_conditions &&
      typeof contentSnippet.field_conditions === 'object'
    ) {
      tooltipContentSnippet = contentSnippet.field_conditions.processed;
    }
  }

  const tooltipContent =
    promotion?.find((item) =>
      filterTimeControl({
        field_valid_from: item.field_promotion_valid_from_date,
        field_valid_until: item.field_promotion_valid_until_date,
      }),
    )?.field_promotion_feedback_descrip ?? tooltipContentSnippet;

  return tooltipContent;
};
