import type { ReactNode } from 'react';

import { Box, ClickAwayListener } from '@packages/shared';

import { cn } from '@packages/shared/src/utils/cn/cn';

import { ResponsiveFullBleedBackground } from './ResponsiveFullBleedBackground';

export type ResponsiveNavigationLayoutProps = {
  children: ReactNode;
  button: ReactNode;
  hamburgerShouldBeMounted: boolean;
  hamburgerClassNames: string;
  isLevel2Visible?: boolean;
  onClickAway?: () => void;
};

export const ResponsiveNavigationLayout = ({
  children,
  button,
  hamburgerClassNames,
  hamburgerShouldBeMounted,
  isLevel2Visible,
  onClickAway,
}: ResponsiveNavigationLayoutProps) => (
  <Box
    component="nav"
    // to do: convert in to native nav element, aria-expanded does not work onn <nav> in native HTML
    className="relative [grid-area:nav]"
    aria-expanded={isLevel2Visible || hamburgerShouldBeMounted}
    // no need to have grid-area:nav here, its already being used on parent div, its not a direct child to the grid container.
  >
    <ResponsiveFullBleedBackground className="border-color-grey-light-dark border-y" />

    {button}

    <ClickAwayListener onClickAway={() => onClickAway?.()}>
      <div
        className={cn(
          !hamburgerShouldBeMounted && 'max-lg:hidden max-lg:-translate-x-full',
          hamburgerClassNames,
          'grid [grid-template:"header"_auto_"toggle"_auto_"content"_1fr_"anchor"_0px_/100%]',
          'max-lg:z-drawer max-lg:bg-color-background-base',
          // todo: refactor to avoid cluttering the div with these arbitrary transition classes
          'max-lg:fixed max-lg:inset-0 max-lg:overflow-y-auto max-lg:shadow',
          'max-lg:[&.transition-enter-active]:[transition:transform_ease-in-out_0.3s,opacity_ease-in-out_0.2s]',
          'max-lg:[&.transition-leave-active]:[transition:transform_ease-in-out_0.3s,opacity_ease-in-out_0.2s]',
          'max-lg:[&.transition-enter-from]:-translate-x-full max-lg:[&.transition-leave-to]:-translate-x-full',
          'max-lg:[&.transition-enter-from]:opacity-0 max-lg:[&.transition-leave-to]:opacity-0',
          'motion-reduce:max-lg:[&.transition-enter-active]:transition-none motion-reduce:max-lg:[&.transition-leave-active]:transition-none',
          'lg:relative',
        )}
      >
        {children}
      </div>
    </ClickAwayListener>
  </Box>
);
