import useSWR from 'swr/immutable';
import { useCmsFetcherDetails } from './useCmsFetcherDetails';
import type { UspData } from '../modules/Usp/types';
import { getUspEndpoint } from '../utils/getUspEndpoint';
import { bucketFetcher } from '../utils/fetcher';

/**
 * Fetches and transforms USP data using the SWR (Stale-While-Revalidate) strategy.
 *
 * @returns The transformed USP data, or undefined if the data is not yet available.
 */
export const useUspData = () => {
  const { language, cacheKey } = useCmsFetcherDetails('usp');
  const endpoint = getUspEndpoint(language);
  const { data } = useSWR<UspData>(cacheKey, () => bucketFetcher<UspData>(endpoint, 240)());

  return data;
};
