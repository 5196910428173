import { cva } from 'class-variance-authority';

export const buttonVariants = cva(
  'button inline-flex items-center justify-center whitespace-nowrap focus-visible:outline-none disabled:opacity-35',
  {
    variants: {
      variant: {
        default:
          'bg-color-grey-light hover:bg-color-grey-light-dark border-color-text-darktransparent border',
        primary: 'button-primary',
        secondary: 'button-secondary',
        additional: 'bg-color-additional text-color-text-light hover:bg-color-additional-dark',
        success: 'bg-color-success text-color-text-light',
        warning: 'bg-color-warning text-color-text-dark',
        error: 'bg-color-error text-color-text-light',
        sale: 'bg-color-sale text-color-sale-contrasttext hover:bg-color-sale-dark',
        link: 'text-color-text-dark underline underline-offset-2',
        'icon-flat': 'bg-color-transparent rounded-full border-none',
        'color-button':
          'hover:border-color-grey border-color-grey-light border-2 border-solid group-[.color-button-rounded]:rounded-full group-[.color-button-active]:outline group-[.color-button-active]:outline-2 group-[.color-button-active]:outline-black',
      },
      size: {
        base: '',
        small:
          'text-size-body3 min-h-3.5 px-[10px] py-[4px] [&>[class^="icon-emp"]]:h-2.5 [&>[class^="icon-emp"]]:w-2.5',
        medium:
          'text-size-body2 min-h-[36px] px-2 py-[6px] [&>[class^="icon-emp"]]:h-[22px] [&>[class^="icon-emp"]]:w-[22px]',
        large:
          'text-size-body1 min-h-[44px] px-[22px] py-[8px] [&>[class^="icon-emp"]]:h-[22px] [&>[class^="icon-emp"]]:w-[22px]',
      },
    },
    defaultVariants: {
      variant: 'default',
      size: 'medium',
    },
    compoundVariants: [
      {
        variant: 'color-button',
        size: 'small',
        className: 'h-[30px] min-h-[30px] w-[30px] min-w-[30px] p-0',
      },
      {
        variant: 'color-button',
        size: 'base',
        className: 'h-[36px] min-h-[36px] w-[36px] min-w-[36px] p-0',
      },
    ],
  },
);
