import type {
  GTMEventGlycerinCampaignClick,
  GTMEventGlycerinDisplayCampaign,
  GenericTrackingEvent,
} from '@packages/tracking/src/types/events';
import { useTracking } from '@packages/tracking/src/hooks/useTracking/useTracking';
import type { RefObject } from 'react';
import config from '@packages/config';
import { useIntersectionObserver } from '../useIntersectionObserver/useIntersectionObserver';
import { useI18n } from '../useI18n/useI18n';

type BehaviorProps = {
  behavior_paragraph_campaign_type?: string | null;
  behavior_paragraph_campaignid?: string | null;
  // for flyout-menu and usp-bar the campaign-id is underscored
  behavior_paragraph_campaign_id?: string | null;
  behavior_paragraph_category?: string | null;
  behavior_paragraph_detail?: string | null;
  behavior_paragraph_name?: string | null;
};

type PromotionProps = {
  glycerinCampaignType?: string;
  glycerinCampaignId?: string;
  glycerinCategory?: string;
  glycerinDetail?: string;
  glycerinName?: string;
  linkReady?: string;
};

type GlycerinCampaignTracking = BehaviorProps | PromotionProps;

const generateFinalTrackingData = (data: GlycerinCampaignTracking, target: string | undefined) => {
  let finalTrackingData: {
    campaignType?: string;
    category?: string;
    campaignId?: string;
    name?: string;
    trackingTarget?: string;
    detail?: string;
  } = {};
  if ('behavior_paragraph_campaign_type' in data) {
    finalTrackingData = {
      campaignType: data.behavior_paragraph_campaign_type || undefined,
      category: data.behavior_paragraph_category || undefined,
      campaignId:
        data.behavior_paragraph_campaignid || data.behavior_paragraph_campaign_id || undefined,
      name: data.behavior_paragraph_name || undefined,
      trackingTarget: target,
      detail: data.behavior_paragraph_detail || undefined,
    };
  } else if ('glycerinCampaignType' in data) {
    finalTrackingData = {
      campaignType: data.glycerinCampaignType,
      category: data.glycerinCategory,
      campaignId: data.glycerinCampaignId,
      name: data.glycerinName,
      trackingTarget: data.linkReady,
      detail: data.glycerinDetail,
    };
  }
  return finalTrackingData;
};

const useReturnOutput = (data: GlycerinCampaignTracking, target?: string) => {
  const { language, defaultLanguage } = useI18n();
  const basePath = config.headerInformation?.de?.canonical ?? '';
  const { campaignType, category, campaignId, name, trackingTarget, detail } =
    generateFinalTrackingData(data, target);
  const dispatchGtmEvent = useTracking();
  if (campaignType && category) {
    let targetUrl: string | undefined;
    try {
      targetUrl = trackingTarget && new URL(trackingTarget, basePath).toString();
    } catch {
      // do nothing
    }
    return () =>
      dispatchGtmEvent<GTMEventGlycerinCampaignClick>({
        event: 'CampaignClick',
        CampaignClickData: {
          campaignType,
          category,
          ...(campaignId && { campaignId }),
          ...(detail && { detail }),
          ...(name && { name }),
          label: language || defaultLanguage,
          ...(targetUrl && { target: targetUrl }),
        },
      });
  }
  return () => {};
};

const executeDisplayTracking = (
  data: GlycerinCampaignTracking,
  target: string | undefined,
  dispatchGtmEvent: <T extends GenericTrackingEvent<string | {}>>(event: T) => void,
) => {
  const { campaignType, category, campaignId, name } = generateFinalTrackingData(data, target);
  if (campaignType && category) {
    dispatchGtmEvent<GTMEventGlycerinDisplayCampaign>({
      event: 'DisplayCampaign',
      DisplayCampaignData: {
        campaignType,
        ...(campaignId && { campaignId }),
        ...(name && { name }),
        category,
      },
    });
  }
};

/**
 * execute glycerin tracking for campaign display and click on viewport
 *
 * @param object glycerin tracking information
 * @param RefObject<Element> the component reference to observe
 * @param string the click target
 * @param boolean this myterious parameter is used to be able to conditionally track on load or on intersect
 * @returns function to execute the dispatcher on click
 */
const useTrackCampaign = (
  data: GlycerinCampaignTracking,
  componentReference: RefObject<Element | null>,
  target?: string,
  trackOnLoad: boolean = false,
) => {
  const dispatchGtmEvent = useTracking();

  useIntersectionObserver(componentReference, {
    intersectOnce: true,
    threshold: 1,
    callback: (isIntersecting) => {
      if (!isIntersecting || trackOnLoad) {
        return;
      }
      // intersectOnce is set to true, so this callback will only be called once
      executeDisplayTracking(data, target, dispatchGtmEvent);
    },
  });

  return { trackClick: useReturnOutput(data, target) };
};

/**
 * execute glycerin tracking for campaign display and click
 *
 * @param object glycerin tracking information
 * @param string the click target
 * @param boolean this myterious parameter is used to be able to conditionally track on load or on intersect
 * @returns function to execute the dispatcher on click
 */
const useTrackCampaignOnLoad = (data: GlycerinCampaignTracking, target?: string) => {
  const dispatchGtmEvent = useTracking();

  return {
    trackDisplay: () => executeDisplayTracking(data, target, dispatchGtmEvent),
    trackClick: useReturnOutput(data, target),
  };
};

export { useTrackCampaign, useTrackCampaignOnLoad };
