import type { ProductConfig } from '../../default/product/product.types';
import type { DeepPartial } from '../../default/types';

export const productConfig: DeepPartial<ProductConfig> = {
  cliplister: {
    customerId: '157279',
  },
  flixmedia: {
    distributorId: 18443,
    enabled: true,
  },
  powerSupply: {
    pictogram: {
      included: 'https://bilder.ackermann.ch/i/empiriecom/FuAG_included.svg',
      notIncluded: 'https://bilder.ackermann.ch/i/empiriecom/FuAG_not_included.svg',
      specification: 'https://bilder.ackermann.ch/i/empiriecom/FuAG_specifications.svg',
    },
  },
  ecoFriendlyDelivery: {
    enabled: true,
  },
};
