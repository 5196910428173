import useSWR from 'swr/immutable';
import config from '@packages/config';
import { useCmsFetcherDetails } from './useCmsFetcherDetails';
import type { CmsData } from '../../interfaces/webapp_json';
import { getEndpoint } from '../utils/cmsFetcher';
import { bucketFetcher } from '../utils/fetcher';

export const useFooterData = () => {
  const { language, cacheKey } = useCmsFetcherDetails('footer');
  const endpoint = getEndpoint(config.footer.apiEndpoints.bucket, 'footer', language);

  const { data } = useSWR<CmsData>(cacheKey, () => bucketFetcher<CmsData>(endpoint, 240)());

  return data;
};
