import { useEffect } from 'react';
import { useCookieConsent } from '@packages/shared/src/hooks/useCookieConsent/useCookieConsent';
import { useTracking } from '@packages/tracking/src/hooks/useTracking/useTracking';
import { Box } from '@packages/shared/src/components/Box/Box';

type TrustedShopsProps = {
  externalApiKey: string;
};

export const TrustedShops = ({ externalApiKey }: TrustedShopsProps) => {
  // the global _tsConfig must be set here because the GTM has no access to global variables
  // eslint-disable-next-line no-underscore-dangle
  (global as any)._tsConfig = {
    yOffset: '0' /* offset from page bottom */,
    variant: 'custom_reviews' /* default, reviews, custom, custom_reviews */,
    customElementId: 'trustedShops' /* required for variants custom and custom_reviews */,
    trustcardDirection: '' /* for custom variants: topRight, topLeft, bottomRight, bottomLeft */,
    customBadgeWidth: '50' /* for custom variants: 40 - 90 (in pixels) */,
    customBadgeHeight: '64' /* for custom variants: 40 - 90 (in pixels) */,
    disableResponsive: 'true' /* deactivate responsive behaviour */,
    disableTrustbadge: 'false' /* deactivate trustbadge */,
  };

  const isTrustedShopsAllowed = useCookieConsent('C0005');
  const dispatchGTMEvent = useTracking();

  useEffect(() => {
    if (isTrustedShopsAllowed) {
      dispatchGTMEvent({
        event: 'renderTrustedShops',
        eventValue: {
          trustedShopsApiKey: externalApiKey,
        },
      });
    }
  }, [isTrustedShopsAllowed, dispatchGTMEvent, externalApiKey]);

  return <Box id="trustedShops" />;
};
