import type { ReactNode } from 'react';
import { forwardRef } from 'react';

import { cn } from '@packages/shared/src/utils/cn/cn';
import { ResponsiveFullBleedBackground } from './ResponsiveFullBleedBackground';

export type SecondaryNavigationContainerProps = {
  children: ReactNode;
  onMouseLeave: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  isVisible: boolean;
};
export const SecondaryNavigationContainer = forwardRef<
  HTMLDivElement | null,
  SecondaryNavigationContainerProps
>(({ children, onMouseLeave, isVisible }, ref) => (
  // Desktop needs two nested divs for the flyout background to work properly, on mobile it would also work with a single container div.
  <div
    className={cn(
      isVisible ? 'flex flex-col' : 'hidden',
      // both z-index and background-color are necessary to hide the L1 categories when using iOS rubber-band scrolling beyond the limit of the container, which shows the content below.
      'z-tooltip',
      'bg-color-background-base',
      // overflow: hidden is necessary on mobile, to act as a scroll container (overflow can't work if the parent doesn't constrain the size)
      'max-lg:overflow-hidden lg:overflow-visible',
      // `anchor` is a zero-height area below the main navigation, which is used as an anchor point for the flyout on desktop
      // `content` is the main content area of the nav dialog on mobile, the same area where the primary nav items are displayed
      '[grid-area:content] lg:[grid-area:anchor]',
    )}
  >
    {/* On desktop, this overflows the outer div to show the flyout. On mobile, it acts as the scroll container for the nav link items. */}
    <div
      // overflow-x: visible is necessary on desktop, to allow the background of the flyout to reach to the edge of the viewport
      // overflow-y: auto is necessary on mobile, to allow the nav link items to scroll inside the dialog
      className="z-tooltip relative flex flex-1 flex-col overflow-y-auto overflow-x-hidden overscroll-contain lg:overflow-x-visible lg:overflow-y-visible"
      ref={ref}
      onMouseLeave={onMouseLeave}
    >
      <ResponsiveFullBleedBackground className="max-lg:transparent lg:bg-color-grey-light" />
      {children}
    </div>
  </div>
));
