import { FlatIconButton } from '@packages/shared/src/components/FlatIconButton/FlatIconButton';
import { useIsHydrated } from '@packages/shared/src/hooks/useIsHydrated/useIsHydrated';
import { BurgerText32 } from '@packages/themes/icons';

export type HamburgerButtonProps = {
  onClick: () => void;
};

export const HamburgerButton = ({ onClick }: HamburgerButtonProps) => {
  const { isHydrated } = useIsHydrated();

  return (
    <FlatIconButton
      sx={{
        margin: 0,
        paddingX: 1,
        display: {
          lg: 'none',
        },
      }}
      aria-label="Open navigation"
      onClick={onClick}
      disabled={!isHydrated}
    >
      <BurgerText32 />
    </FlatIconButton>
  );
};
