import { Container } from '@packages/shared/src/components/Container/Container';
import { Box } from '@packages/shared/src/components/Box/Box';
import type { JSX } from 'react';
import type { ParagraphType } from '../../../../interfaces';
import type { CmsData } from '../../../../interfaces/webapp_json';
import type { FooterBlockType } from '../types';
import { ComponentRecognizer } from '../../ComponentRecognizer/ComponentRecognizer';
import { useFilterTimeControl } from '../../../hooks/useFilterTimeControl';

type AreaTopProps = {
  data: CmsData;
};

export enum AreaTopTestIds {
  top = 'top',
  noRenderComponentFound = 'top-no-component-found',
}

export const AreaTop = ({ data }: AreaTopProps): JSX.Element => {
  const filterTimeControl = useFilterTimeControl();
  const comp = (data.paragraph as FooterBlockType[])
    .filter((item) => item?.parent_field_name === 'field_footer_top' && filterTimeControl(item))
    .map((item: ParagraphType, index) => (
      <ComponentRecognizer
        // eslint-disable-next-line react/no-array-index-key
        key={`${AreaTopTestIds.top}-item-${index}`}
        item={{ ...item, ...{ trackOnLoad: true } }}
      />
    ));

  if (comp.length === 0) {
    return (
      <Box
        sx={{
          mt: 5,
        }}
        data-testid={AreaTopTestIds.noRenderComponentFound}
      />
    );
  }

  return <Container data-testid={AreaTopTestIds.top}>{comp}</Container>;
};
