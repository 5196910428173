import { useEffect } from 'react';
import DOMPurify from '@packages/config/utils/dompurify';
import { Box } from '@packages/shared/src/components/Box/Box';
import { useTracking } from '@packages/tracking/src/hooks/useTracking/useTracking';
import { useConfig } from '@packages/shared/src/hooks/useConfig/useConfig';
import { useProcessHtmlLinks } from '../../hooks/useProcessHtmlLinks';
import { getFinalDangerouslyHTML } from '../../utils/getFinalDangerouslyHTML';
import { onLoad, waitUntilReady } from '../../utils/onLoadTracking';
import type { HtmlProps } from './types';
import { registerComponents } from '../ComponentRecognizer/componentRegistry';
import { ComponentTypes } from '../../../interfaces';
import { CmsTable } from '../CmsTable/CmsTable';

export const htmlHeadlineLevels = [1, 2, 3, 4, 5, 6] as const;

export enum HtmlTestIds {
  main = 'dangerously-html',
}

/**
 * Component that renders an HTML paragraph and styles its containing tags
 */
export const Html = ({ data, sx, component }: HtmlProps) => {
  const {
    field_distance: distanceType,
    field_margin: margin = '0',
    field_padding: padding = '0',
    field_background_color: bgColor = 'transparent',
    field_active_border: activeBorder = false,
    field_html_editor: htmlContent,
    field_text_align: textAlign = 'left',
    field_onload_tracking_name: onloadTrackingName,
    field_onload_tracking_key: onloadTrackingKey,
    field_onload_tracking_value: onloadTrackingValue,
  } = data;

  const dispatchGTMEvent = useTracking();
  const getHtmlContent = getFinalDangerouslyHTML(htmlContent);
  const processHtmlLinks = useProcessHtmlLinks();
  const tmpHTML = processHtmlLinks(getHtmlContent ?? '');
  const finalHTML = tmpHTML.replace(/{--}/g, '&shy;');
  const {
    staticContent: { cmsFontToTheme },
  } = useConfig();

  /**
   * If tracking params are set, fire the event once the dataLayer is ready
   */
  useEffect(() => {
    if (onloadTrackingName && onloadTrackingKey && onloadTrackingValue) {
      waitUntilReady(
        () => !!(window as any).dataLayer,
        () => {
          onLoad('trackFooterHtmlLoad', dispatchGTMEvent, {
            onloadTrackingName,
            onloadTrackingKey,
            onloadTrackingValue,
          });
        },
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatchGTMEvent]);

  /**
   * Styling for all headline types h1, h2, .., h6
   */
  const headlineStyles = htmlHeadlineLevels.reduce(
    (result, h) => ({
      ...result,
      [`h${h}`]: { typography: `h${h}` },
    }),
    {},
  );

  // delegate to cmsTable if table_json exists
  if ('table_json' in data) {
    return <CmsTable data={data} />;
  }
  return (
    <Box
      component={component || 'div'}
      sx={{
        '& h1, & h2, & h3, & h4, & h5, & h6, & ul, & ol, & li, & p': {
          margin: 0,
          padding: 0,
          paddingBottom: 1,
          typography: 'body2',
        },
        '& p, & ul li, & ol li': {
          '&:last-of-type': {
            paddingBottom: 0,
          },
        },
        '& ul, & ol': {
          margin: '0 0 0 1rem',
          '&:last-of-type': {
            marginBottom: 0,
          },
        },
        '&& a': {
          color: 'text.dark',
          '&:hover': {
            color: 'grey.dark',
          },
          textDecoration: 'underline',
        },
        '&': cmsFontToTheme,
        margin: `${margin} !important`,
        ...(distanceType === 'variation-1' && {
          paddingInline: { xs: 3, lg: 0 },
        }),
        ...(distanceType !== 'variation-1' && {
          padding: `${padding} !important`,
        }),
        textAlign,
        backgroundColor: bgColor,
        borderWidth: activeBorder ? '1px' : '',
        borderStyle: activeBorder ? 'solid' : '',
        borderColor: activeBorder ? 'info.main' : 'transparent',
        '& ul li': {
          listStyleType: 'disc',
        },
        '& ol li': {
          listStyleType: 'decimal',
        },
        '& table td a': {
          display: 'inline-block',
        },
        '& button': {
          borderWidth: '1px',
          backgroundColor: 'grey.light',
          borderRadius: '3px',
          paddingBlock: '0.25rem',
          paddingInline: '0.5rem',
        },
        ...headlineStyles,
        ...sx,
      }}
      data-testid={HtmlTestIds.main}
      className={HtmlTestIds.main}
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(finalHTML) }}
    />
  );
};

// Needed to run this component in Storybook and Jest tests. Register the component in a global componentRegistry object
registerComponents({
  [ComponentTypes.Html]: Html,
  [ComponentTypes.HtmlParagraph]: Html,
});
