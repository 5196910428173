import { defineMessage, useIntl } from 'react-intl';

import { Cart32 } from '@packages/themes/icons';
import { useI18n } from '@packages/shared/src/hooks/useI18n/useI18n';

import { BasketSlotDocument } from '@packages/gql/generated/shopping/BasketSlotDocument';
import { useAuthQuery } from '@packages/shared/src/hooks/useAuthQuery/useAuthQuery';
import { useEffect } from 'react';
import { getLocalizedPath } from '@packages/shared/src/utils/getLocalizedPath/getLocalizedPath';
import { useConfig } from '@packages/shared/src/hooks/useConfig/useConfig';
import { Slot } from './Slot';
import { useIsCheckoutRoute } from './useIsCheckoutRoute';
import { useSessionCartInfoTracking } from './BasketSlot.useSessionCartInfoTracking';

const title = defineMessage({
  id: 'slot.basket.displaytext',
  defaultMessage: 'Warenkorb',
});

/* GraphQL */ `
  query BasketSlot($locale: String!) {
    basket(locale: $locale, skipRecalculation: true) {
      itemCount
      subTotal {
        amount
        currency
      }
      lineItems {
        extra {
          description
          variation
          size
          promotion
          breadcrumbs
          mkz
          category
        }
        quantity
        product {
          orderNumber
        }
      }
    }
  }
`;

/** Cart slot */
export const BasketSlot = () => {
  const { formatMessage } = useIntl();
  const { locale, language } = useI18n();
  const config = useConfig();
  const isCheckoutRoute = useIsCheckoutRoute();
  const [{ data, fetching }, executeQuery] = useAuthQuery({
    query: BasketSlotDocument,
    variables: { locale },
    requestPolicy: 'cache-and-network',
  });

  useEffect(() => {
    // this is a workaround to update the basket count badge when the basket is updated outside the next.js app
    // the event could be dispatched from the legacy fragments order or wishlist
    const eventListener: EventListener = () => {
      executeQuery({ requestPolicy: 'network-only' });
    };

    if (window) {
      window.addEventListener('basketUpdated', eventListener);
    }

    return () => {
      if (window) {
        window.removeEventListener('basketUpdated', eventListener);
      }
    };
  }, [executeQuery]);

  useSessionCartInfoTracking({ fetching, data });

  if (isCheckoutRoute) return null;

  return (
    <Slot
      language={language}
      useRouterLink={false}
      icon={<Cart32 />}
      linkAriaLabel={formatMessage(title)}
      href={getLocalizedPath(config.order.paths.basket, locale)}
      badgeCount={!fetching && data ? data.basket.itemCount : 0}
      title={formatMessage(title)}
      sx={{ minWidth: { xl: '3rem' } }}
    />
  );
};
