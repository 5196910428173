import { useRef, type FC, useEffect, useState, Fragment } from 'react';
import { Card, CardMedia, CardContent, CardActionArea, Stack, Divider, Box } from '@mui/material';
import { Typography } from '@packages/shared/src/components/Typography/Typography';
import { Flag } from '@packages/shared/src/components/Flag/Flag';
import { EfficiencyFlag } from '@packages/shared/src/components/EfficiencyFlag/EfficiencyFlag';
import { ProductPrice } from '@packages/shared/src/components/ProductPrice/ProductPrice';
import { ProductColors } from '@packages/shared/src/components/ProductColors/ProductColors';
import { Image, getImageSize } from '@packages/shared/src/components/Image/Image';
import { Link } from '@packages/shared/src/components/Link/Link';
import { useDeviceType } from '@packages/shared/src/hooks/useDeviceType/useDeviceType';
import { useI18n } from '@packages/shared/src/hooks/useI18n/useI18n';
import { RatingDisplay } from '@packages/shared/src/components/RatingDisplay/RatingDisplay';
import { useTracking } from '@packages/tracking/src/hooks/useTracking/useTracking';
import { useIsBot } from '@packages/tracking/src/hooks/useIsBot/useIsBot';
import type {
  GTMEventGlycerinAdClick,
  GTMEventGlycerinProductClick,
} from '@packages/tracking/src/types/events';
import type {
  GlycerinAdClickPayload,
  GlycerinProductClickPayload,
} from '@packages/tracking/src/types/payloads';
import { getCanonicalUrl } from '@packages/shared/src/utils/getCanonicalUrl/getCanonicalUrl';
import { useRouter } from 'next/navigation';
import { PowerSupply } from '@packages/shared/src/ui/PowerSupply/PowerSupply';
import { WishlistButtonWithApi } from '../WishlistButtonWithApi/WishlistButtonWithApi';
import { Title } from './Title';
import type { ProductCardProps, ProductCardFlag } from './types';
import { ProductAvailabilityOverlay } from './ProductAvailabilityOverlay/ProductAvailabilityOverlay';

// Get image file name without extension from absolute URL
export const getImageFileName = (path: string): string => {
  const fileNameWithExtension = path.substring(path.lastIndexOf('/') + 1); // "file.txt"
  const fileName = fileNameWithExtension.split('.').slice(0, -1).join('.'); // "file"
  return fileName;
};

const getSortedFilteredFlags = ({
  minimal,
  flags,
}: {
  minimal: boolean;
  flags: ProductCardFlag[];
}) => {
  // sort flags by type
  const sortedFlags = flags.sort((a: ProductCardFlag, b: ProductCardFlag) => {
    const order = ['custom', 'sale', 'neutral', 'secondary', 'sustainability'];
    return order.indexOf(a.type || '') - order.indexOf(b.type || '');
  });

  const filteredFlags = sortedFlags.filter((flag: ProductCardFlag) => {
    // minimal product card has no custom flag
    if (minimal) {
      return flag.type !== 'custom';
    }
    // normal product card has no sale flag
    return flag.type !== 'sale';
  });

  // minimal product card has only 2 flags, otherwise 3
  return minimal ? filteredFlags.slice(0, 2) : filteredFlags.slice(0, 3);
};

const MIN_WIDTH_FOR_THREE_COLORS = 184;

/**
 * ProductCard component based on https://mui.com/material-ui/react-card/
 * */
export const ProductCard: FC<ProductCardProps> = ({
  availability,
  variationName,
  sku,
  productLink = '#',
  image,
  brand,
  title,
  price,
  colors,
  minimal = false,
  flags,
  efficiencyFlags,
  datasheets,
  additionalInfo,
  priority,
  rating,
  isSponsored,
  sx,
  onColorChange,
  onProductLinkActionAreaClick,
  shrinkToFit = false,
  useAdditionalFlagsSpacer,
  useRatingsSpacer,
  clickTrackingProps,
  wishListTrackingProps,
  powerSupplyProps,
  ...rest
}) => {
  const { isDesktop } = useDeviceType();
  const [visibleColorsCount, setVisibleColorsCount] = useState(3);
  const cardRef = useRef<HTMLDivElement>(null);
  const dispatchGTMEvent = useTracking();
  const { localizeUrl, language } = useI18n();

  const { push } = useRouter();

  const px = minimal ? [0.5, null, 1] : 'auto';

  const hasAvailabilityInfo =
    availability && (availability.alternateText || availability.values?.length > 0);

  useEffect(() => {
    const element = cardRef?.current;

    if (!element) return;

    const observer = new ResizeObserver(() => {
      setVisibleColorsCount(element.clientWidth >= MIN_WIDTH_FOR_THREE_COLORS ? 3 : 2);
    });

    observer.observe(element);
    // eslint-disable-next-line consistent-return
    return () => {
      // Cleanup the observer by unobserving all elements
      observer.disconnect();
    };
  }, []);

  const handleActionAreaClick = () => {
    if (isSponsored) {
      // Sponsored Products click needs to be an adClick event
      dispatchGTMEvent<GTMEventGlycerinAdClick>({
        event: 'AdClick',
        AdClickData: {
          category: clickTrackingProps?.category ?? 'sponsored-product-click',
          adId: (clickTrackingProps as GlycerinAdClickPayload)?.adId,
          adType: (clickTrackingProps as GlycerinAdClickPayload)?.adType ?? 'sponsored product',
          target: (clickTrackingProps as GlycerinAdClickPayload)?.target ?? productLink,
          placement: clickTrackingProps?.placement,
          detail: clickTrackingProps?.detail,
          label: clickTrackingProps?.label,
          listPosition: clickTrackingProps?.listPosition,
          custom: clickTrackingProps?.custom,
          variantIds: clickTrackingProps?.variantIds,
        },
      });
    } else {
      // "regular" product click
      dispatchGTMEvent<GTMEventGlycerinProductClick>({
        event: 'ProductClick',
        ProductClickData: {
          category: clickTrackingProps?.category ?? 'product-click',
          productId: (clickTrackingProps as GlycerinProductClickPayload)?.productId,
          placement: clickTrackingProps?.placement,
          articleId: (clickTrackingProps as GlycerinProductClickPayload)?.articleId,
          detail: clickTrackingProps?.detail,
          imageType: (clickTrackingProps as GlycerinProductClickPayload)?.imageType,
          label: clickTrackingProps?.label,
          listId: (clickTrackingProps as GlycerinProductClickPayload)?.listId,
          listPosition: (clickTrackingProps as GlycerinProductClickPayload)?.listPosition,
          origin: (clickTrackingProps as GlycerinProductClickPayload)?.origin,
          recoId: (clickTrackingProps as GlycerinProductClickPayload)?.recoId,
          skuId:
            availability?.values.length === 1
              ? (clickTrackingProps as GlycerinProductClickPayload)?.skuId
              : undefined,
          custom: clickTrackingProps?.custom,
          variantIds: clickTrackingProps?.variantIds,
        },
      });
    }
    if (onProductLinkActionAreaClick) {
      onProductLinkActionAreaClick();
    }
  };

  let linkTarget = productLink;
  const isBot = useIsBot();
  if (isBot) {
    linkTarget = getCanonicalUrl(productLink);
  }

  const imageSize = getImageSize(image) ?? { width: 339, height: 485 };

  const titleWithBrand = `${brand} ${title}`;

  return (
    <div className="group relative h-full">
      <Link
        className="absolute inset-0 z-20 cursor-pointer"
        href={linkTarget}
        title={titleWithBrand}
        onClick={handleActionAreaClick}
      />
      {!isSponsored && (
        <WishlistButtonWithApi
          productName={titleWithBrand}
          sku={sku}
          size={minimal ? 'medium' : 'large'}
          sx={{
            position: 'absolute',
            right: 4,
            top: 4,
            zIndex: 1000,
            '.MuiButton-startIcon > *:nth-of-type(1)': {
              fontSize: 32,
            },
          }}
          availability="UNSELECTED"
          trackingProps={
            wishListTrackingProps
              ? {
                  products: [
                    {
                      // TODO: availability is currently pretty hard to map to the wishlist types, try to add it with or after the GraphQL migration
                      // availability: availability?.values?.[0]?.availabilityName,
                      currency:
                        price.currency === 'EUR' || price.currency === 'CHF'
                          ? price.currency
                          : 'EUR',
                      displayedPrice: price.price,
                      originalPrice: price.oldPrice,
                      ...wishListTrackingProps.product,
                    },
                  ],
                }
              : undefined
          }
        />
      )}
      <Card
        {...rest}
        ref={cardRef}
        title={titleWithBrand}
        className="group-hover:bg-color-grey-light"
        sx={{
          ...sx,
          display: 'flex',
          position: 'relative',
          flexDirection: additionalInfo ? 'row' : 'column',
          height: '100%',
        }}
      >
        <Box
          sx={{
            position: 'relative',
            width: additionalInfo ? '50%' : '100%',
            zIndex: 20,
            pointerEvents: 'none',
            '> *': { pointerEvents: 'none' },
          }}
        >
          <Box sx={{ zIndex: additionalInfo ? 2 : 1, position: 'relative' }}>
            <div className="absolute inset-0 z-10 bg-[#000] opacity-0 group-hover:opacity-[0.04]" />
            {flags && (
              <Stack
                spacing={0.5}
                sx={{
                  position: 'absolute',
                  left: 0,
                  top: 0,
                  zIndex: 1,
                  m: 1,
                  alignItems: 'flex-start',
                }}
              >
                {getSortedFilteredFlags({ flags, minimal }).map((flag) =>
                  flag.type === 'custom' ? (
                    // flag with custom image
                    <Image
                      width="68"
                      height="28"
                      style={{
                        height: 'max-content',
                        maxHeight: 28,
                        objectFit: 'contain',
                        objectPosition: 'left',
                      }}
                      src={flag.src}
                      alt={getImageFileName(flag.src)}
                      key={flag.src}
                    />
                  ) : (
                    <Flag text={flag.text} type={flag.type} key={flag.text} />
                  ),
                )}
              </Stack>
            )}
            <CardMedia
              component="div"
              sx={{
                position: 'relative',
                height: shrinkToFit ? 'auto' : imageSize.height,
                maxHeight: imageSize.height,
                display: 'flex',
                alignItems: 'center',
                overflow: 'hidden',
                '> img': {
                  width: '100%',
                  height: 'auto',
                  objectFit: 'contain',
                  maxWidth: '100%',
                  maxHeight: '100%',
                },
                ...(minimal && { maxHeight: 200 }),
                ...(additionalInfo && { maxHeight: 400, maxWidth: 'calc(100% - 1px)' }),
              }}
            >
              <Image
                src={image}
                alt={titleWithBrand}
                width={imageSize.width}
                height={imageSize.height}
                priority={priority}
              />
            </CardMedia>
            {isDesktop && hasAvailabilityInfo && variationName && (
              <ProductAvailabilityOverlay
                onProductLinkClick={onProductLinkActionAreaClick}
                availability={availability}
                variationName={variationName}
                alternateLink={linkTarget}
                className="opacity-0 group-hover:opacity-100"
              />
            )}
          </Box>
          {additionalInfo && <Divider orientation="vertical" absolute sx={{ zIndex: 1 }} />}
        </Box>
        <Box
          aria-live="polite"
          sx={{
            display: 'flex',
            position: 'relative',
            flexDirection: 'column',
            flexGrow: 1,
            width: additionalInfo ? '50%' : '100%',
          }}
        >
          <CardContent
            sx={{
              display: 'flex',
              flexDirection: 'column',
              width: '100%',
              flexGrow: 1,
              px,
              py: minimal ? 1 : 'auto',
              ...(colors && !isSponsored && { pb: 0.5 }),
              // '&:last-child': { pb: 0.5 },
            }}
          >
            <Typography color="text.darkTransparent" variant="body2" mb={1}>
              {brand}
            </Typography>
            <Title minimal={minimal}>{title}</Title>
            <ProductPrice {...price} hasDiscountFlag={!minimal} />
          </CardContent>

          {colors && !minimal && !isSponsored && (
            <CardContent sx={{ pt: 0.5 }}>
              <ProductColors
                colorsVisible={visibleColorsCount}
                colors={colors}
                onColorChange={(index: number) => {
                  if (typeof onColorChange === 'function') {
                    onColorChange(index);
                  }
                  handleActionAreaClick();
                }}
                onMoreLabelClick={() => push(localizeUrl(linkTarget, language))}
                className="[&>button]:z-20"
              />
            </CardContent>
          )}

          {additionalInfo && (
            <CardContent
              sx={{
                pt: 0.5,
                px,
                py: minimal ? 1 : 'auto',
                display: 'flex',
                flexDirection: 'column',
                flexGrow: 1,
              }}
            >
              <Typography component="ul" variant="body2" sx={{ pl: 2, listStyleType: 'disc' }}>
                {additionalInfo.map((data, index) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <li key={`${data}-${index}`}>{data}</li>
                ))}
              </Typography>
            </CardContent>
          )}

          {rating?.avgRating && (
            <CardContent sx={{ pt: 0 }}>
              <RatingDisplay avgRating={rating.avgRating} reviewCount={rating.reviewCount} />
            </CardContent>
          )}

          {useRatingsSpacer && !rating?.avgRating && <Box sx={{ padding: 0, height: '40px' }} />}

          {efficiencyFlags && (
            <>
              <Divider />
              {efficiencyFlags.some((x) => x.datasheet) ? (
                <CardContent
                  sx={{
                    display: 'grid',
                    gridTemplateColumns: 'auto 1fr',
                    gap: 1,
                    px,
                    py: 1,
                    zIndex: 20,
                    position: 'relative',
                  }}
                >
                  {efficiencyFlags.map((flagProps, index) => (
                    <Fragment key={flagProps.overlay?.title ?? index}>
                      <EfficiencyFlag {...flagProps} />
                      <Stack sx={{ justifyContent: 'center' }}>
                        {efficiencyFlags.length > 1 && flagProps.overlay && (
                          <Typography variant="body3" fontWeight="600">
                            {flagProps.overlay.title}
                          </Typography>
                        )}
                        {flagProps.datasheet && (
                          <Link
                            href={flagProps.datasheet.url}
                            target="_blank"
                            // NOTE: currently there is no canonical way of having monochrome links, they default to primary (both text and decoration)
                            sx={(theme) => ({
                              color: theme.palette.text.dark,
                              textDecorationColor: theme.palette.text.dark,
                              '&:hover': {
                                color: theme.palette.grey.dark,
                                textDecorationColor: theme.palette.grey.dark,
                              },
                            })}
                          >
                            <Typography variant="body3">{flagProps.datasheet.name}</Typography>
                          </Link>
                        )}
                      </Stack>
                    </Fragment>
                  ))}
                </CardContent>
              ) : (
                <CardContent
                  sx={{
                    display: 'flex',
                    flexFlow: 'row wrap',
                    gap: 1,
                    px,
                    py: 1,
                    zIndex: 20,
                    position: 'relative',
                  }}
                >
                  {efficiencyFlags.map((flagProps, index) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <EfficiencyFlag {...flagProps} key={index} />
                  ))}
                </CardContent>
              )}
              {datasheets?.map((datasheet) => (
                <CardActionArea
                  key={datasheet.url}
                  href={datasheet.url}
                  target="_blank"
                  sx={{ position: 'relative', zIndex: 20 }}
                >
                  <CardContent
                    sx={{
                      color: 'text.dark',
                      typography: 'body2',
                      textDecoration: 'underline',
                      px,
                      py: 1,
                    }}
                  >
                    {datasheet.name}
                  </CardContent>
                </CardActionArea>
              ))}
            </>
          )}
          {useAdditionalFlagsSpacer && !efficiencyFlags && (
            <Box
              sx={{
                height: datasheets?.length ? '71px' : '53px', // +28px for wrapping flags
                p: 0,
              }}
            />
          )}

          {powerSupplyProps && (
            <>
              <Divider />
              <div className="p-1">
                <PowerSupply {...powerSupplyProps} />
              </div>
            </>
          )}
        </Box>
      </Card>
    </div>
  );
};
