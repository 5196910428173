import { Box } from '@packages/shared/src/components/Box/Box';
import { Container } from '@packages/shared/src/components/Container/Container';
import type { JSX } from 'react';
import { ComponentRecognizer } from '../../ComponentRecognizer/ComponentRecognizer';
import type { CmsData } from '../../../../interfaces/webapp_json';
import type { FooterBlockType } from '../types';

type BlockContentProps = {
  data: CmsData;
};

export enum BlockContentTestIds {
  main = 'block-content',
  headline = 'block-content-headline',
  noRenderComponentFound = 'block-content-no-component-found',
}

export const BlockContent = ({ data }: BlockContentProps): JSX.Element => {
  const comp = (data.paragraph as FooterBlockType[])
    ?.filter((item) => item.parent_field_name === 'field_footer')
    ?.map((item, index) => {
      const { field_headline: headline } = item;
      const customKeyMain = `main-${index}`;
      const customKeyHeadline = `headline-${index}`;

      return (
        <Box
          component="div"
          sx={{
            width: 'calc(71.25rem / 4)',
            display: 'inline-block',
            verticalAlign: 'top',
            mb: 7.5,
            '& a': {
              textDecoration: 'none',
              color: 'inherit',
            },
            typography: 'body2',
          }}
          key={customKeyMain}
        >
          {headline && (
            <Box
              sx={{
                mb: 1.5,
                fontWeight: '600',
              }}
              data-testid={BlockContentTestIds.headline}
              key={customKeyHeadline}
            >
              {headline}
            </Box>
          )}
          <ComponentRecognizer item={item} />
        </Box>
      );
    });

  if (comp.length > 0) {
    return (
      <Container
        sx={{
          display: 'flex',
          flexFlow: 'row wrap',
          flexDirection: 'row',
          alignItems: 'stretch',
          width: '100%',
          p: 0,
          pt: 8.5,
          '> div': {
            boxSizing: 'border-box',
            m: 0,
            mr: 0,
            mb: 8.5,
            padding: 0,
            flexBasis: 'calc(100% / 4)',
          },
        }}
        data-testid={BlockContentTestIds.main}
      >
        {comp}
      </Container>
    );
  }

  return <div data-testid={BlockContentTestIds.noRenderComponentFound} />;
};
