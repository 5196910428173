import { buildLinkWithArguments } from '../../../utils/buildLinkWithArguments';
import type { DrupalPromotion } from '../../../../interfaces';
import type {
  ContentSnippetType,
  PromotionBannerItemType,
} from '../../../../interfaces/contentSnippet';
import type { AccordionPromotion, LinkPromotion, Promotion } from '../types';

export const mapDrupalPromotionToPromotion = (
  drupalPromotion: DrupalPromotion,
  cartCode?: string,
  testDate?: string,
) => {
  const currentDate = testDate ? new Date(testDate).getTime() : new Date().getTime();

  const {
    // get name of Promotion
    name,

    // information to filter, select and assign the correct promotion
    field_promotion_code: textsCode, // code that identifies the promotion
    field_promotion_valid_from_date: validFromDate,
    field_promotion_valid_until_date: validUntilDate,
    field_promotion_pro: pro, // array of SKUs to assign this promotion to
    field_promotion_description: description, // this description is used in the UI
    field_payback_promotion: isPaybackPromotion,

    // information for the UI
    field_promotion_feedback_descrip: feedbackDescription, // this description is used in the UI if this promotion exists in the shopping cart
    field_promotion_feedback_title: feedbackTitle, // this title is used in the UI if this promotion exists in the shopping cart
    field_promotion_max_order_value: maxOrderValue,
    field_promotion_min_order_value: minOrderValue,
    field_promotion_title: title,
    field_promotion_link: link,

    // information to build the link if a linked promotion is used
    field_promotion_link_bonusnumber: linkBonusnumber,
    field_promotion_link_filter: linkFilter,
    field_promotion_link_highlight: linkHighlight,
    field_promotion_link_tracking: linkTracking,
    field_promotion_seo_description: seoDescription,

    // information for seo
    field_promotion_customer_group: seoCustomerGroup, // customer group identifier to assign the promotion to a specific customer segment
    field_promotion_seo_headline: seoHeadline,
    field_promotion_seo_online: seoOnline,
    field_promotion_seo_subline: seoSubline,
    field_promotion_seo_valid_from: seoValidFrom,
    field_promotion_seo_valid_until: seoValidUntil,
    field_promotion_seo_value: seoValue,

    // glycerin tracking
    field_campaign_type: glycerinCampaignType,
    field_campaign_id: glycerinCampaignId,
    field_category: glycerinCategory,
    field_detail: glycerinDetail,
    field_name: glycerinName,
  } = drupalPromotion;

  const validFromDateTime = new Date(validFromDate || currentDate - 1800).getTime();
  const validUntilDateTime = new Date(validUntilDate || currentDate + 1800).getTime();
  const isExpired = !(validFromDateTime < currentDate && currentDate < validUntilDateTime);

  return {
    name,
    textsCode,
    cartCode: cartCode || textsCode,
    seoCustomerGroup,
    description,
    feedbackDescription,
    feedbackTitle,
    isPaybackPromotion: !!isPaybackPromotion,
    link,
    linkBonusnumber,
    linkFilter,
    linkHighlight,
    linkTracking,
    linkReady:
      link &&
      buildLinkWithArguments(link, {
        field_link_highlight: linkHighlight || undefined,
        field_link_searchfilter: linkFilter || undefined,
        field_bonus_number: linkBonusnumber || undefined,
        field_tracking: linkTracking || undefined,
      }),
    maxOrderValue,
    minOrderValue,
    pro,
    seoDescription,
    seoHeadline,
    seoOnline,
    seoSubline,
    seoValidFrom,
    seoValidUntil,
    seoValue,
    title,
    validFromDate,
    validUntilDate,
    promotionAsLink: !!(link && !textsCode),
    isExpired,
    ...(glycerinCampaignId && { glycerinCampaignId }),
    ...(glycerinCampaignType && { glycerinCampaignType }),
    ...(glycerinCategory && { glycerinCategory }),
    ...(glycerinDetail && { glycerinDetail }),
    ...(glycerinName && { glycerinName }),
  } as AccordionPromotion | LinkPromotion;
};

export const mapDrupalContentSnippetsToPromotions = (
  drupalContentSnippets: ContentSnippetType[],
  testDate?: string,
): (Promotion | undefined)[] =>
  drupalContentSnippets.map((drupalContentSnippet) => {
    const newItem = mapDrupalPromotionToPromotion(
      (drupalContentSnippet as PromotionBannerItemType).field_paragraph.field_promotion,
      undefined,
      testDate,
    );
    if (newItem && (drupalContentSnippet as PromotionBannerItemType).field_user_status?.name) {
      newItem.userStatus = (
        drupalContentSnippet as PromotionBannerItemType
      ).field_user_status?.name;
      newItem.snippetName = (drupalContentSnippet as PromotionBannerItemType).label;
    }
    return newItem;
  });
