/* istanbul ignore file */
import {
  createClient,
  fetchExchange,
  ssrExchange,
  Provider,
  cacheExchange as documentCacheExchange,
} from 'urql';
import { cacheExchange } from '@urql/exchange-graphcache';
import type { ReactNode } from 'react';
import { useEffect, useMemo, useRef } from 'react';
import { reviewsPagination } from './reviewsPagination';
import { keys } from './cacheKeySettings';

export const createGqlApiUrl = (isServer: boolean = false) =>
  // GQL_GATEWAY is set as env variable in the deploymment values to enable direct routing with the local pod url on serverside
  process.env.GQL_GATEWAY || `${isServer ? process.env.SERVER_HOST : ''}/api/gateway/`;

export function createUrqlClient(
  initialState?: NonNullable<Parameters<typeof ssrExchange>[0]>['initialState'],
) {
  const ssrExCache = ssrExchange({
    isClient: typeof window !== 'undefined',
    initialState,
  });

  return {
    ssrExCache,
    client: createClient({
      url: createGqlApiUrl(),
      exchanges: [
        cacheExchange({
          // TODO: add schema awareness after merging endpoints and the introspection is correct
          // schema: schema as CacheExchangeOpts['schema'],
          keys,
          resolvers: {
            Query: {
              rating: reviewsPagination(),
            },
          },
          // NOTE uncomment this if you are having inexplicable `null` values from `useQuery`, and want to find out why
          // logger: (...args) => console.log(...args);
        }),
        ssrExCache,
        fetchExchange,
      ],
      suspense: true,
    }),
  };
}

export const createUrqlServerClient = () => {
  const ssrCache = ssrExchange({
    isClient: false,
    initialState: {},
  });

  return {
    ssrCache,
    ssrClient: createClient({
      url: createGqlApiUrl(true),
      exchanges: [documentCacheExchange, ssrCache, fetchExchange],
      suspense: false,
      requestPolicy: 'network-only',
    }),
  };
};

export const UrqlProvider = ({ children, pageProps }: { children: ReactNode; pageProps: any }) => {
  const initialRender = useRef(true);

  const { ssrExCache, client: urqlClient } = useMemo(() => {
    const client = createUrqlClient(pageProps.urqlState);
    // Serialize the urqlClient to null on the client-side.
    // This ensures we don't share client and server instances of the urqlClient.
    (client.client as any).toJSON = () => null;

    return client;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // restore server fetched data on client page transition
  if (pageProps.urqlState && !initialRender.current) {
    ssrExCache.restoreData(pageProps.urqlState);
  }

  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false;
    }
  }, []);

  return <Provider value={urqlClient}>{children}</Provider>;
};
