import { SvgIcon, SvgIconProps } from "@mui/material";
import { memo } from "react";
const SvgSuccessCircle32 = (props: SvgIconProps) => {
  const {
    sx,
    ...other
  } = props;
  return <SvgIcon xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 32 32" sx={{
    ...sx
  }} {...other}><circle cx={16} cy={16} r={16} fill="#7DAE2E" /><path fill="#fff" d="m14.048 16.324 5.656-5.657 2.828 2.83-8.484 8.483-4.715-4.713 2.828-2.828z" /></SvgIcon>;
};
const Memo = memo(SvgSuccessCircle32);
export default Memo;