import { useCookieConsent } from '@packages/shared/src/hooks/useCookieConsent/useCookieConsent';
import { useEffect, useMemo } from 'react';
import { useTracking } from '@packages/tracking/src/hooks/useTracking/useTracking';

export const Zoovu = ({ advisorId, zoovuURL }: { advisorId?: string; zoovuURL?: string }) => {
  const isZoovuAllowed: boolean = useCookieConsent('C0002');
  const dispatchGTMEvent = useTracking();
  const eventValue = useMemo(
    () => (advisorId ? { zoovuAdvisorId: advisorId } : { zoovuURL }),
    [advisorId, zoovuURL],
  );

  useEffect(() => {
    if (isZoovuAllowed) {
      dispatchGTMEvent({
        event: advisorId ? 'renderZoovuScript' : 'renderZoovuScriptURL',
        eventValue,
      });
    }
  }, [advisorId, dispatchGTMEvent, eventValue, isZoovuAllowed]);

  return (
    <div key="zoovu-script">
      <div id="zoovu-assistant" data-scroll-behavior="noscroll" />
    </div>
  );
};
