import type { Language } from '@packages/config';
import config from '@packages/config';
import DOMPurify from '@packages/config/utils/dompurify';
import type { CmsData } from '../../../interfaces/webapp_json';

export const schemaData = (data: CmsData) => {
  const {
    field_headline_long: headline,
    field_description_long: description,
    taxonomy_term: taxonomy,
    file,
    created,
    changed,
    path,
    langcode,
  } = data;
  const {
    headerInformation,
    host: { domain },
    tracking: {
      schemaOrgOrganisation: {
        'de-DE': { logo },
      },
    },
  } = config;
  const canonical = headerInformation[langcode as Language]?.canonical;
  let pageUrl;
  try {
    pageUrl = new URL(path?.[0].alias, canonical);
  } catch {
    pageUrl = path?.[0].alias;
  }
  return {
    '@context': 'https://schema.org/',
    '@type': 'Article',
    name: headline,
    description,
    headline,
    keywords: taxonomy?.map((keyword) => keyword.name),
    image: {
      '@type': 'ImageObject',
      url: file?.[0].url,
      caption: file?.[0].data.alt,
    },
    about: {
      '@type': 'BlogPost',
      name: headline,
    },
    datePublished: created,
    dateModified: changed,
    url: pageUrl.href,
    publisher: {
      '@type': 'Organization',
      name: domain,
      logo,
    },
    author: {
      '@type': 'Organization',
      name: domain,
      url: canonical,
      logo,
    },
  };
};

export const SchemaOrg = ({ data }: { data: CmsData }) => {
  if (data.type !== 'magazin_content') {
    return null;
  }

  return (
    <script
      type="application/ld+json"
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(JSON.stringify(schemaData(data))) }}
    />
  );
};
