import type { ReactNode } from 'react';
import { forwardRef } from 'react';

import { cn } from '@packages/shared/src/utils/cn/cn';

export type PrimaryNavigationContainerProps = {
  children: ReactNode;
  onMouseLeave: (event: React.MouseEvent<HTMLUListElement, MouseEvent>) => void;
  isVisible: boolean;
};
//
export const PrimaryNavigationContainer = forwardRef<
  HTMLUListElement | null,
  PrimaryNavigationContainerProps
>(({ children, onMouseLeave, isVisible }, ref) => (
  <ul
    ref={ref}
    className={cn(
      isVisible ? 'max-lg:grid' : 'max-lg:hidden',
      'm-0 flex overflow-hidden p-0',
      'max-lg:[grid-auto-rows:7.5rem] max-lg:[grid-template-columns:repeat(auto-fill,_minmax(10rem,1fr))]',
      'max-lg:pt-4.5 max-lg:m-0 max-lg:w-full max-lg:place-items-stretch max-lg:gap-2 max-lg:overflow-y-auto max-lg:overscroll-contain max-lg:px-1.5 max-lg:pb-1.5',
      '[grid-area:content]',
      'lg:max-h-[calc(theme(spacing[5.5])_+_2px)] lg:min-h-[calc(theme(spacing[5.5])_+_2px)] lg:list-none lg:flex-wrap',
    )}
    onMouseLeave={onMouseLeave}
  >
    {children}
  </ul>
));
