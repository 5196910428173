import type { Alignment, Font, LegacyFont } from '../../../interfaces';
import type { CmsImageParagraph } from '../CmsImage/types';

export type OverlayRectangleType = CmsImageParagraph & {
  type: 'flexible_teaser';
  field_type?: 'flexible_teaser';
  field_border_color?: string;
  field_headline?: string;
  field_text_subheadline?: string;
  field_font?: LegacyFont | Font;
  field_font_subheadline?: LegacyFont | Font;
  field_layout_variant?: string;
  field_negative_text_color?: boolean;
  field_size?: string;
  field_size_subheadline?: string;
  field_vertical_alignment?: Alignment;
  field_name?: string;
  field_background_color?: string;
  field_scaling_factor?: number;
  field_alignment?: Alignment;
};

export enum FontSizeVariants {
  Big = '2.25rem',
  Medium = '1.5rem',
  Small = '1rem',
}

export enum LineHeightVariants {
  Big = '2.4375rem',
  Medium = '1.8rem',
  Small = '1.3rem',
}
