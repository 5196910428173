import { useIntl } from 'react-intl';
import { powerSupplyMessages } from './messages';
import { PowerSupplyPictogram } from '../PowerSupplyPictogram/PowerSupplyPictogram';
import type { PowerSupplyProps } from './PowerSupplyProps';

export const PowerSupply = ({
  maxPower,
  minPower,
  chargerType,
  powerSupplyIncluded,
  asCard = false,
  showPowerSupplyMessage = false,
  ...rest
}: PowerSupplyProps) => {
  const { formatMessage } = useIntl();

  return (
    <div
      {...rest}
      className={`flex ${asCard ? 'flex-row' : 'flex-col'} flex-nowrap gap-0.5 ${asCard && 'border-color-grey items-center gap-1 rounded-[8px] border p-1.5'}`}
    >
      {showPowerSupplyMessage && (
        <div className={`text-size-body3 ${asCard ? 'order-2' : 'order-1'}`}>
          {formatMessage(
            powerSupplyIncluded === 'included'
              ? powerSupplyMessages.included
              : powerSupplyMessages.notIncluded,
          )}
        </div>
      )}
      <div className={`flex flex-row gap-1 ${asCard ? 'order-1' : 'order-2'} `}>
        <PowerSupplyPictogram
          layout={powerSupplyIncluded === 'included' ? 'Included' : 'NotIncluded'}
        />
        <PowerSupplyPictogram
          layout="Specification"
          chargerType={chargerType}
          maxPower={maxPower}
          minPower={minPower}
        />
      </div>
    </div>
  );
};
