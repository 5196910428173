'use client';

import type { Config } from '@packages/config/src/default/types';
import { dateCompare } from '../dateCompare/dateCompare';
import { parseTestDate } from '../timeUtils/timeUtils';

export type TimeControl = {
  field_valid_from?: string | null;
  field_valid_to?: string | null;
  field_valid_until?: string | null;
  field_visible_from?: string | null;
  field_visible_until?: string | null;
  behavior_paragraph_time_control_valid_from?: string | null;
  behavior_paragraph_time_control_valid_until?: string | null;
  behavior_paragraph_visibility_officehours?:
    | 'unset'
    | 'during-office-hours'
    | 'outside-office-hours';
};

const weekday = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat'] as const;

/**
 * Converts the Date object that "new Date()" generates to the given time zone
 * @param timeZone The desired time zone (e.g. "Europe/Amsterdam")
 * @returns New date object for the desired time zone that returns the correct local time hours and minutes
 */
export const getLocalTime = (timeZone: Config['timeZone'] = 'Europe/Berlin') => {
  const currentDate: Date = new Date();

  const formatter = new Intl.DateTimeFormat([], {
    timeZone,
    hourCycle: 'h23',
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
  });

  const dateObj = formatter.formatToParts(currentDate);

  const valueFor = (type: string) => dateObj.find((d) => d.type === type)?.value;
  const dateLocalTimeZoneString = `${valueFor('year')}-${valueFor('month')}-${valueFor('day')}T${valueFor('hour')}:${valueFor('minute')}`;

  return new Date(dateLocalTimeZoneString);
};

/**
 * Determines if the current time (or time in testDate) is within the configured office time range.
 * Example: {
      mon: '08:00-21:00',
      tue: '08:00-21:00',
      wed: '08:00-21:00',
      thu: '08:00-21:00',
      fri: '08:00-21:00',
      sat: '08:00-12:00',
    }
 * */
const isInsideOfficeTime = (officeTimes: Config['officeTimes'], currentDate: Date) => {
  const currentDayConfig = officeTimes[weekday[currentDate.getDay()]];

  // if there is no config for today, today the office is closed.
  if (!currentDayConfig) {
    return false;
  }

  const hour = currentDate.getHours();
  const minute = currentDate.getMinutes();

  // "08:00-21:00" -> ["08:00", "21:00"]
  const [timeRangeStart, timeRangeEnd] = currentDayConfig.split('-');

  // "08:00" -> [8,0]
  const [timeRangeHourStart, timeRangeMinuteStart] = timeRangeStart
    .split(':')
    .map((str) => Number.parseInt(str, 10));

  // "21:00" -> [21,0]
  const [timeRangeHourEnd, timeRangeMinuteEnd] = timeRangeEnd
    .split(':')
    .map((str) => Number.parseInt(str, 10));

  // shop is open if we are inside opening hours
  if (timeRangeHourStart < hour && hour < timeRangeHourEnd) {
    return true;
  }

  // shop is closed if we are before or after opening hours
  if (hour < timeRangeHourStart || hour > timeRangeHourEnd) {
    return false;
  }

  // start/ending hours are equal to current hour
  const minutesSinceMidnight = hour * 60 + minute;
  const closingTimeMinutes = timeRangeHourEnd * 60 + timeRangeMinuteEnd;
  const openingTimeMinutes = timeRangeHourStart * 60 + timeRangeMinuteStart;

  return openingTimeMinutes <= minutesSinceMidnight && minutesSinceMidnight <= closingTimeMinutes;
};

export const filterTimeControl = (
  item: TimeControl,
  testDate: string | string[] | undefined | null = undefined,
  officeTimes: Config['officeTimes'] = {},
  timeZone: Config['timeZone'] = 'Europe/Berlin',
): boolean => {
  // support valid from and valid until fields from paragraphs and content snippets INSPIRE-2298
  const {
    field_valid_from: validFrom,
    field_valid_to: validTo,
    field_valid_until: validUntil,
    field_visible_from: visibleFrom,
    field_visible_until: visibleUntil,
    behavior_paragraph_time_control_valid_from: behaviorValidFrom,
    behavior_paragraph_time_control_valid_until: behaviorValidUntil,
    behavior_paragraph_visibility_officehours: visibilityOfficeHours = 'unset',
  } = item;

  // if there is a setting for office hours visibility, use only this and ignore normal time control.
  if (visibilityOfficeHours !== 'unset') {
    const isInsideOfficeHours = isInsideOfficeTime(
      officeTimes,
      parseTestDate({ testDate }) || getLocalTime(timeZone),
    );
    return visibilityOfficeHours === 'during-office-hours'
      ? isInsideOfficeHours
      : !isInsideOfficeHours;
  }

  return dateCompare({
    validFrom: behaviorValidFrom || validFrom || visibleFrom,
    validTo: behaviorValidUntil || validTo || visibleUntil || validUntil,
    testDate:
      (Array.isArray(testDate) ? testDate[0] : testDate) ?? getLocalTime(timeZone).toISOString(),
  });
};
