'use client';

import { useLoginState } from '@packages/shared/src/hooks/useLoginState/useLoginState';
import { Stack } from '@packages/shared/src/components/Stack/Stack';
import { UspLink } from './UspLink';
import type { UspWrapperProps, UspItem } from './types';

const dynamicIDs = [
  'usp_replace-first-usp',
  'usp_replace-second-usp',
  'usp_replace-third-usp',
  'usp_replace-forth-usp',
];

/**
 * UspWrapper component.
 *
 * @param {object} props - Component props.
 * @param {object} props.data - The USP data.
 *
 * @returns JSX.Element|null The rendered UspWrapper component or null if no valid children exist.
 */
export const UspWrapper = ({ data }: UspWrapperProps) => {
  const { loggedIn } = useLoginState();
  const uspData = loggedIn ? data['1'] : data['0'];

  const validChildren = Object.values(uspData.children || []).filter(
    (item): item is UspItem => item && typeof item === 'object',
  );

  if (!validChildren.length) {
    return null;
  }

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      sx={{
        margin: '0 auto',
        maxWidth: 'xl',
      }}
      spacing={2}
    >
      {validChildren.map((uspItem: UspItem, index: number) => (
        <UspLink key={dynamicIDs[index]} dynamicID={dynamicIDs[index]} uspItem={uspItem} />
      ))}
    </Stack>
  );
};
