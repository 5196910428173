import { logger } from '@packages/shared/src/utils/logger/logger';
import { fetchPromoData } from './fetchPromoData';
import type { PromotionDataFetchInformation } from './fetch.types';
import { decodeFromBase64 } from '../../../../utils/decodeFromBase64';

export const fetchSelectedPromotionData = async ({
  query,
  locale,
  callOwnApi,
  cookies,
}: Pick<PromotionDataFetchInformation, 'query' | 'locale' | 'callOwnApi' | 'cookies'>) => {
  const { BonusNumber, P1, ParentCode, TestDate: testDate } = query;
  const { pendingPromo } = cookies;
  const selectedBonusNumber = BonusNumber || (P1 && decodeFromBase64(P1)) || pendingPromo;
  if (!selectedBonusNumber) {
    return undefined;
  }
  try {
    return await fetchPromoData(
      { cart: selectedBonusNumber, texts: ParentCode || selectedBonusNumber },
      locale,
      callOwnApi,
      testDate,
    );
  } catch (error) {
    logger.error({ error }, 'Failed to fetch selected promotion data');
    throw error;
  }
};
